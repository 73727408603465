/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import {
  getAlt,
  getExtractedSvg,
  getImage,
  getLottie,
} from "@bond-london/gatsby-graphcms-components";
import { getCleanedRTF, rtfFromText } from "@bond-london/graphcms-rich-text";
import { graphql } from "gatsby";
import React from "react";
import {
  Article,
  ArticleInformation,
  CenterCarousel,
  CenterCarouselContent,
  Collection,
  CollectionContent,
  Form,
  HillsAndValleysCarousel,
  HillsAndValleysCarouselContent,
  Infographic,
  Lever,
  PageLink,
  PeopleCollection,
  PositionedAsset,
  Quote,
  SimpleTextBlock,
  Statement,
  StatementCarousel,
  StatementInformation,
  StatisticDetails,
  TextField,
  ValuesCollection,
  WideCarousel,
  WideCarouselContent,
} from ".";
import {
  GraphCms_Article,
  GraphCms_ArticleCollection,
  GraphCms_Asset,
  GraphCms_CaseStudy,
  GraphCms_Form,
  GraphCms_FormField,
  GraphCms_Infographic,
  GraphCms_Insight,
  GraphCms_Page,
  GraphCms_PageBlocks,
  GraphCms_PageLink,
  GraphCms_PageLinkCollection,
  GraphCms_PeopleCollection,
  GraphCms_Person,
  GraphCms_PositionedAsset,
  GraphCms_Quote,
  GraphCms_RichTextBlock,
  GraphCms_SectorPage,
  GraphCms_Statement,
  GraphCms_StatementCollection,
  GraphCms_Story,
} from "../generated/graphql-types";
import { getProfileInformation, lookupColour } from "../utils";
import { ContentBlock } from "./ContentBlock";
import { FeaturesCollection } from "./FeaturesCollection";
import { SingleProfile } from "./SingleProfile";

interface Props {
  blocks: readonly GraphCms_PageBlocks[];
}

function buildPeopleCollection(collection: GraphCms_PeopleCollection) {
  return (
    <PeopleCollection
      key={collection.id}
      title={collection.showName ? collection.name : undefined}
      individualFirstLine={collection.individualFirstLine}
      fullFirstLine={collection.fullFirstLine}
      animation={getLottie(collection.lottie)}
      contents={collection.people.map((p) => (
        <SingleProfile
          key={p.name}
          {...p}
          image={getImage(p.image)}
          showTitle={true}
          showQuote={true}
          showCountry={true}
          hoverExpand={p.quote ? true : false}
          link={getLink(p)}
        />
      ))}
    />
  );
}

function buildStatementInformation(
  statement: GraphCms_Statement
): StatementInformation {
  return {
    id: statement.id,
    title: statement.title,
    headline: statement.headline,
    message: statement.message,
    className: lookupColour(statement.backgroundColour, "Background"),
    externalVideoUrl: statement.externalVideoUrl,
    externalVideoTitle: statement.externalVideoLinkTitle,
  };
}

function buildValuesCollection(collection: GraphCms_StatementCollection) {
  return (
    <ValuesCollection
      key={collection.id}
      title={collection.name}
      heading={
        getCleanedRTF(collection.heading) || rtfFromText("Heading is empty")
      }
      statements={collection.statements.map((statement) => ({
        id: statement.id,
        title: statement.title,
        heading: getCleanedRTF(statement.message),
      }))}
    />
  );
}

function buildFeaturesCollection(collection: GraphCms_StatementCollection) {
  return (
    <FeaturesCollection
      key={collection.id}
      title={collection.name}
      heading={getCleanedRTF(collection.heading)}
      statements={collection.statements.map((statement) => ({
        id: statement.id,
        title: statement.title,
        heading: getCleanedRTF(statement.message),
      }))}
      subText={collection.subText}
    />
  );
}

function buildStatementCollection(collection: GraphCms_StatementCollection) {
  switch (collection.collectionType) {
    case "Values":
      return buildValuesCollection(collection);
    case "Features":
      return buildFeaturesCollection(collection);
  }
  return (
    <StatementCarousel
      key={collection.id}
      id={collection.id}
      statements={collection.statements.map(buildStatementInformation)}
    />
  );
}

function buildQuote(quote: GraphCms_Quote) {
  return (
    <Quote
      key={quote.id}
      quote={quote.quote}
      profile={getProfileInformation(quote.person)}
      author={quote.author}
      authorJobTitle={quote.authorJobTitle}
      authorPosition={quote.authorPosition}
    />
  );
}

function buildArticleInformation(
  article: GraphCms_Article
): ArticleInformation {
  return {
    id: article.id,
    image: getImage(article.image),
    alt: getAlt(article.image, article.title || "Image"),
    side: article.imageLeft ? "left" : "right",
    title: article.title,
    detailsRTF: getCleanedRTF(article.message),
  };
}
function buildArticle(article: GraphCms_Article) {
  return <Article key={article.id} {...buildArticleInformation(article)} />;
}

function buildInfographic(infographic: GraphCms_Infographic) {
  return (
    <Infographic
      key={infographic.id}
      title={infographic.title}
      isTitleVisible={infographic.isTitleVisible}
      headlineRTF={getCleanedRTF(infographic.message)}
      statistics={infographic.details as StatisticDetails[]}
      backgroundColour={infographic.background}
      dotsAnimation={infographic.animation}
    />
  );
}

function buildArticleCollection(collection: GraphCms_ArticleCollection) {
  return (
    <WideCarousel
      key={collection.id}
      id={collection.id}
      contents={collection.articles.map((a, index) => (
        <WideCarouselContent
          id={a.id}
          index={index}
          image={getImage(a.image)}
          key={a.id}
          title={a.title}
          messageRTF={getCleanedRTF(a.message)}
          narrowImage={undefined}
        />
      ))}
    />
  );
}

function buildStatement(statement: GraphCms_Statement) {
  return (
    <Statement key={statement.id} {...buildStatementInformation(statement)} />
  );
}

function resolveSlug(prefix: string, slug: string | undefined) {
  if (slug === "home") {
    return "/";
  }

  if (slug) {
    return prefix + slug;
  }
  return "/";
}

type PageLinkContent =
  | GraphCms_CaseStudy
  | GraphCms_Insight
  | GraphCms_Page
  | GraphCms_SectorPage
  | GraphCms_Story;

function processLink<T>(
  pageLink: GraphCms_PageLink | undefined,
  typename: string | undefined,
  handler: (
    content: PageLinkContent | undefined,
    prefix: string,
    typename: "CaseStudy" | "Insight" | "Page" | "SectorPage" | "Story"
  ) => T | undefined
) {
  if (pageLink?.caseStudy?.slug || typename === "GraphCMS_CaseStudy") {
    return handler(pageLink?.caseStudy, "/case-study/", "CaseStudy");
  }
  if (pageLink?.insight?.slug || typename === "GraphCMS_Insight") {
    return handler(pageLink?.insight, "/insight/", "Insight");
  }

  if (pageLink?.page?.slug || typename === "GraphCMS_Page") {
    return handler(pageLink?.page, "/", "Page");
  }

  if (pageLink?.sectorPage?.slug || typename === "GraphCMS_SectorPage") {
    const sectorPage = pageLink?.sectorPage;
    if (sectorPage) {
      const path = `/${sectorPage.sectorType.toLowerCase()}/`;
      return handler(pageLink?.sectorPage, path, "SectorPage");
    }
  }

  if (pageLink?.story?.slug || typename === "GraphCMS_Story") {
    return handler(pageLink?.story, "/story/", "Story");
  }
}

function getPossibleLink(
  page: GraphCms_PageLink | GraphCms_Person | undefined,
  slug?: string,
  typename?: string
) {
  if (page?.__typename === "GraphCMS_Person") {
    return resolveSlug("/people/", page.slug);
  }
  const result = processLink<string>(
    page as GraphCms_PageLink,
    typename,
    (content, prefix) => resolveSlug(prefix, slug || content?.slug)
  );
  return result;
}

export function getLink(
  page: GraphCms_PageLink | GraphCms_Person | undefined,
  slug?: string,
  typename?: string
): string {
  return getPossibleLink(page, slug, typename) || "/";
}

function getLinkType(page: GraphCms_PageLink | GraphCms_Person) {
  if (page.__typename === "GraphCMS_Person") {
    return "Person";
  }

  return processLink(
    page as GraphCms_PageLink,
    undefined,
    (_a, _b, name) => name
  );
}

function getLinkTagline(page: GraphCms_PageLink | GraphCms_Person) {
  const linkType = getLinkType(page);
  switch (linkType) {
    case "CaseStudy":
      return "Case Study";
    case "SectorPage":
      return "Sector";
  }
  return linkType;
}

function isPerson(
  personOrLink: GraphCms_Person | GraphCms_PageLink
): personOrLink is GraphCms_Person {
  return personOrLink.__typename === "GraphCMS_Person";
}

function getLinkImage(
  personOrLink: GraphCms_PageLink | GraphCms_Person
): GraphCms_Asset | undefined {
  if (isPerson(personOrLink)) {
    return personOrLink.image;
  }

  if (personOrLink?.optionalImage) {
    return personOrLink.optionalImage;
  }

  return processLink(personOrLink, undefined, (content) => {
    switch (content?.__typename) {
      case "GraphCMS_CaseStudy":
        return content.businessChallengeImage;
      case "GraphCMS_Insight":
        return content.heroImage;
      case "GraphCMS_Page":
        return content.heroImage;
      case "GraphCMS_SectorPage":
        return content.heroImage;
      case "GraphCMS_Story":
        return undefined;
    }
  });
}

function getLinkTitle(
  personOrLink: GraphCms_PageLink | GraphCms_Person
): string {
  if (isPerson(personOrLink)) {
    return personOrLink.name;
  }

  if (personOrLink.optionalTitle) {
    return personOrLink.optionalTitle;
  }

  const result = processLink(personOrLink, undefined, (content) => {
    switch (content?.__typename) {
      case "GraphCMS_CaseStudy":
        return content.businessChallenge;
      case "GraphCMS_Insight":
        return content.title;
      case "GraphCMS_Page":
        return content.title;
      case "GraphCMS_SectorPage":
        return content.sector;
      case "GraphCMS_Story":
        return content.title;
    }
  });
  return result || "Title";
}

function getLinkRTF(page: GraphCms_PageLink | GraphCms_Person) {
  if (page.__typename === "GraphCMS_Person") {
    return rtfFromText(page.name);
  }
  if (page.__typename === "GraphCMS_PageLink") {
    if (page.message) {
      return getCleanedRTF(page.message);
    }

    return processLink(page, undefined, (content) => {
      switch (content?.__typename) {
        case "GraphCMS_CaseStudy":
          return content.businessChallenge
            ? rtfFromText(content.businessChallenge)
            : undefined;
        case "GraphCMS_Insight":
          return getCleanedRTF(content.summary);
        case "GraphCMS_Page":
          {
            const message = content.heroMessage?.[0];
            if (message) {
              return rtfFromText(message);
            }
          }
          break;
        case "GraphCMS_SectorPage":
          return getCleanedRTF(content.statement);
        case "GraphCMS_Story":
          return getCleanedRTF(content.details);
      }
    });
  }
}

function buildPageLink(link: GraphCms_PageLink) {
  return (
    <PageLink
      key={link.id}
      id={link.id}
      link={getLink(link)}
      title={getLinkTitle(link)}
      messageRTF={getLinkRTF(link)}
      image={getImage(getLinkImage(link))}
      tagline="In the spotlight"
    />
  );
}

function buildPageLinkCollection(collection: GraphCms_PageLinkCollection) {
  switch (collection.carouselType) {
    case "Center":
      return (
        <CenterCarousel
          key={collection.id}
          id={collection.id}
          title={collection.showName ? collection.name : undefined}
          contents={collection.pageLinks.map((s, index) => {
            const image = getLinkImage(s);
            return (
              <CenterCarouselContent
                id={s.id}
                index={index}
                key={s.id}
                tagline={getLinkTagline(s)}
                image={getImage(image)}
                alt={getAlt(image, s.optionalTitle || "Image")}
                title={getLinkTitle(s)}
                messageRTF={getLinkRTF(s)}
                link={getLink(s)}
                narrowImage={s.narrowImage}
              />
            );
          })}
        />
      );

    case "HillsAndValleys":
      return (
        <HillsAndValleysCarousel
          key={collection.id}
          id={collection.id}
          title={collection.showName ? collection.name : undefined}
          contents={collection.pageLinks.map((s, index) => {
            const image = getLinkImage(s);
            return (
              <HillsAndValleysCarouselContent
                id={s.id}
                index={index}
                key={s.id}
                image={getImage(image)}
                alt={getAlt(image, s.optionalTitle || "Image")}
                title={getLinkTitle(s)}
                messageRTF={getLinkRTF(s)}
                link={getLink(s)}
                highlightName={s.highlightName}
                narrowImage={s.narrowImage}
              />
            );
          })}
        />
      );

    case "Wide":
      return (
        <WideCarousel
          key={collection.id}
          id={collection.id}
          title={collection.showName ? collection.name : undefined}
          contents={collection.pageLinks.map((s, index) => {
            const image = getLinkImage(s);
            return (
              <WideCarouselContent
                id={s.id}
                index={index}
                key={s.id}
                tagline={
                  collection.showNameInContent
                    ? collection.name
                    : getLinkTagline(s)
                }
                image={getImage(image)}
                alt={getAlt(image, s.optionalTitle || "Image")}
                title={getLinkTitle(s)}
                messageRTF={getLinkRTF(s)}
                link={getPossibleLink(s)}
                narrowImage={s.narrowImage}
              />
            );
          })}
        />
      );

    case "ConsultingJobs":
      return (
        <Lever
          key={collection.id}
          title={collection.name}
          showTitle={collection.showName}
          department="Consulting"
        />
      );

    case "GlobalServicesJobs":
      return (
        <Lever
          key={collection.id}
          title={collection.name}
          showTitle={collection.showName}
          // department="PGS"
          department="Global Services"
        />
      );

    case "LeverJobs":
      return (
        <Lever
          key={collection.id}
          title={collection.name}
          showTitle={collection.showName}
        />
      );
  }
  return (
    <Collection
      key={collection.id}
      id={collection.id}
      title={collection.showName ? collection.name : undefined}
      contents={collection.pageLinks.map((s) => {
        const image = getLinkImage(s);
        console.log(getLink(s));
        return (
          <CollectionContent
            key={s.id}
            image={getImage(image)}
            alt={getAlt(image, s.optionalTitle || "Image")}
            title={getLinkTitle(s)}
            messageContent={getLinkRTF(s)}
            link={
              getLink(s) == "/diversity"
                ? "/../en/our-social-values"
                : getLink(s)
            }
          />
        );
      })}
    />
  );
}

function buildRichTextBlock(block: GraphCms_RichTextBlock) {
  return (
    <SimpleTextBlock
      contentRTF={getCleanedRTF(block.contents)}
      key={block.id}
    />
  );
}

export function buildTextField(field: GraphCms_FormField): TextField {
  return {
    title: field.placeholder || field.name,
    name: field.name,
    type: field.fieldType,
    choices: field.choices,
    required: !!field.required,
  };
}

function buildForm(form: GraphCms_Form) {
  return (
    <Form
      key={form.id}
      title={form.optionalTitle}
      tittle2={form.optionalTitle2}
      postUrl={form.postUrl}
      buttonText={form.buttonText}
      inputs={form.formFields.map(buildTextField)}
      thankYouText={form.thankYouMessage}
      errorText={form.submissionErrorMessage}
    />
  );
}

function buildPositionedAsset(positionedAsset: GraphCms_PositionedAsset) {
  return (
    <PositionedAsset
      key={positionedAsset.id}
      lottie={getLottie(positionedAsset.asset)}
      alt={getAlt(positionedAsset.asset, positionedAsset.name)}
      svg={getExtractedSvg(positionedAsset.asset)}
      image={getImage(positionedAsset.asset)}
      position={positionedAsset.position}
      size={positionedAsset.size}
    />
  );
}

export const Blocks: React.FC<Props> = ({ blocks }) => {
  return (
    <>
      {blocks.map((block) => {
        switch (block.__typename) {
          case "GraphCMS_PeopleCollection":
            return buildPeopleCollection(block);
          case "GraphCMS_PageLink":
            return buildPageLink(block);
          case "GraphCMS_StatementCollection":
            return buildStatementCollection(block);
          case "GraphCMS_Quote":
            return buildQuote(block);
          case "GraphCMS_Article":
            return buildArticle(block);
          case "GraphCMS_Infographic":
            return buildInfographic(block);
          case "GraphCMS_ArticleCollection":
            return buildArticleCollection(block);
          case "GraphCMS_Statement":
            return buildStatement(block);
          case "GraphCMS_PageLinkCollection":
            return buildPageLinkCollection(block);
          case "GraphCMS_RichTextBlock":
            return buildRichTextBlock(block);
          case "GraphCMS_Form":
            return buildForm(block);
          case "GraphCMS_PositionedAsset":
            return buildPositionedAsset(block);
          case "GraphCMS_GenericContentBlock":
            return <ContentBlock block={block} />;
          default:
            return <h1>No support for {block.__typename}</h1>;
        }
      })}
    </>
  );
};

export const PositionedAssetFragment = graphql`
  fragment PositionedAssetFragment on GraphCMS_PositionedAsset {
    id
    asset {
      ...LottieFragment
      ...ImageFragment
      ...SvgFragment
    }
    name
    position
    size
  }
`;

export const StatementFragment = graphql`
  fragment StatementFragment on GraphCMS_Statement {
    id
    headline
    message {
      cleaned
      references {
        ...ImageFragment
      }
    }
    title
    backgroundColour
    externalVideoUrl
    externalVideoLinkTitle
  }
`;

export const CaseStudyLinkFragment = graphql`
  fragment CaseStudyLinkFragment on GraphCMS_CaseStudy {
    __typename
    id
    slug
    headline
    businessChallenge
    businessChallengeImage {
      ...ImageFragment
    }
  }
`;
export const CaseStudyReferenceFragment = graphql`
  fragment CaseStudyReferenceFragment on GraphCMS_CaseStudy {
    ...CaseStudyLinkFragment
    pageLink {
      ...PageLinkOnlyFragment
    }
  }
`;

export const StoryLinkFragment = graphql`
  fragment StoryLinkFragment on GraphCMS_Story {
    __typename
    id
    slug
    title
  }
`;

export const StoryReferenceFragment = graphql`
  fragment StoryReferenceFragment on GraphCMS_Story {
    ...StoryLinkFragment
    pageLink {
      ...PageLinkOnlyFragment
    }
  }
`;

export const InsightLinkFragment = graphql`
  fragment InsightLinkFragment on GraphCMS_Insight {
    __typename
    id
    slug
    title
    heroImage {
      ...ImageFragment
    }
  }
`;
export const InsightReferenceFragment = graphql`
  fragment InsightReferenceFragment on GraphCMS_Insight {
    ...InsightLinkFragment
    pageLink {
      ...PageLinkOnlyFragment
    }
  }
`;

export const PeopleCollectionFragment = graphql`
  fragment PeopleCollectionFragment on GraphCMS_PeopleCollection {
    id
    name
    showName
    individualFirstLine
    fullFirstLine
    people {
      ...ProfileFragment
    }
    lottie {
      ...LottieFragment
    }
  }
`;

export const StatementCollectionFragment = graphql`
  fragment StatementCollectionFragment on GraphCMS_StatementCollection {
    id
    name
    heading {
      cleaned
    }
    collectionType
    statements {
      id
      headline
      message {
        cleaned
      }
      title
    }
    subText
  }
`;

export const QuoteFragment = graphql`
  fragment QuoteFragment on GraphCMS_Quote {
    id
    quote
    person {
      ...ProfileFragment
    }
    author
    authorJobTitle
    authorPosition
  }
`;

export const ArticleFragment = graphql`
  fragment ArticleFragment on GraphCMS_Article {
    id
    title
    message {
      cleaned
    }
    image {
      ...ImageFragment
    }
    imageLeft
  }
`;

export const ArticleCollectionFragment = graphql`
  fragment ArticleCollectionFragment on GraphCMS_ArticleCollection {
    id
    articles {
      ...ArticleFragment
    }
  }
`;

export const InfographicFragment = graphql`
  fragment InfographicFragment on GraphCMS_Infographic {
    id
    title
    message {
      cleaned
    }
    details
    background
    animation
    isTitleVisible
  }
`;

export const SectorPageLinkFragment = graphql`
  fragment SectorPageLinkFragment on GraphCMS_SectorPage {
    __typename
    id
    slug
    sectorType
    sector
    heroImage {
      ...ImageFragment
    }
  }
`;
export const SectorPageFragment = graphql`
  fragment SectorPageFragment on GraphCMS_SectorPage {
    ...SectorPageLinkFragment
    statement {
      cleaned
    }
    pageLink {
      ...PageLinkOnlyFragment
    }
  }
`;

export const PageLinkOnlyFragment = graphql`
  fragment PageLinkOnlyFragment on GraphCMS_PageLink {
    id
    optionalTitle
    optionalImage {
      ...ImageFragment
    }
    narrowImage
    message {
      cleaned
    }
    highlightName
  }
`;

export const PageLinkFullFragment = graphql`
  fragment PageLinkFullFragment on GraphCMS_PageLink {
    __typename
    ...PageLinkOnlyFragment
    insight {
      ...InsightLinkFragment
    }
    page {
      ...GenericPageLinkFragment
    }
    sectorPage {
      ...SectorPageLinkFragment
    }
    story {
      ...StoryLinkFragment
    }
    caseStudy {
      ...CaseStudyLinkFragment
    }
  }
`;

export const PageLinkCollectionFragment = graphql`
  fragment PageLinkCollectionFragment on GraphCMS_PageLinkCollection {
    id
    name
    showName
    showNameInContent
    carouselType
    pageLinks {
      ...PageLinkFullFragment
    }
  }
`;

export const RichTextBlockFragment = graphql`
  fragment RichTextBlockFragment on GraphCMS_RichTextBlock {
    id
    name
    contents {
      cleaned
    }
  }
`;

export const FormFragment = graphql`
  fragment FormFragment on GraphCMS_Form {
    id
    name
    optionalTitle
    optionalTitle2
    postUrl
    buttonText
    thankYouMessage
    submissionErrorMessage
    formFields {
      id
      name
      fieldType
      placeholder
      choices
      required
    }
  }
`;
