/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable import/no-unresolved */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import classNames from "classnames";
import React, {
  HTMLInputTypeAttribute,
  useCallback,
  useRef,
  useState,
} from "react";
import { GraphCms_FormFieldType } from "../generated/graphql-types";
// import { type } from "os";
import { Helmet } from "react-helmet";
// import { ReCAPTCHA } from "react-google-recaptcha";
import { Link, navigate } from "gatsby";
import { colors } from "react-select/dist/declarations/src/theme";
export interface TextField {
  title: string;
  name: string;
  type: GraphCms_FormFieldType;
  choices?: readonly string[];
  required: boolean;
}

function buildState(fields: TextField[]) {
  const state: { [key: string]: string } = {};
  fields.forEach(({ name }) => (state[name] = ""));
  return state;
}

interface Props {
  title?: string;
  tittle2?: string;
  postUrl: string;
  buttonText: string;
  inputs: TextField[];
  thankYouText?: string;
  errorText?: string;
}

function buildInputType(
  fieldType: GraphCms_FormFieldType
): HTMLInputTypeAttribute {
  switch (fieldType) {
    case "Email":
      return "email";
    case "PhoneNumber":
      return "tel";
    default:
      return "text";
  }
}

// const grecaptchaObject = window.grecaptcha;
{
  /* <script src="https://www.google.com/recaptcha/api.js"></script>; */
}
export const Form: React.FC<Props> = ({
  title,
  buttonText,
  inputs,
  postUrl,
  thankYouText,
  errorText,
  tittle2,
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const formRef = useRef<HTMLElement>(null);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState<string>();
  const [disabled, setDisabled] = useState(false);
  const [query, setQuery] = useState(() => buildState(inputs));
  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const element = e.currentTarget;
      const name = element.name;
      const value = element.value;
      setQuery((prev) => ({ ...prev, [name]: value }));
      setDisabled(false);
      // console.log("Captcha value:", value);
    },
    []
  );
  // window.initMap = initMap;
  const onSelectChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      const element = e.currentTarget;
      const name = element.name;
      const value = element.value;
      setQuery((prev) => ({ ...prev, [name]: value }));
      setDisabled(false);
    },
    []
  );

  let map: google.maps.Map, popup;
  const locations = [
    [
      "Partners in Performance",
      -26.039326225489024,
      28.01850025589003,
      4,
      "Africa",
      "+27 11 463 0903",
      //"+971 50 677 4367",
    ],
    [
      "Partners in Performance",
      -33.86584575476446,
      151.20885264071615,
      3,
      "Australia",
      "+61 2 9321 0800",
    ],
    [
      "Partners in Performance",
      33.90606718951541,
      -84.43571307300243,
      2,
      "North America",
      "+1 404 400 6590",
    ],
    [
      "Partners in Performance",
      -36.84456544649881,
      174.7705542696262,
      1,
      "New Zealand",
      "+61 2 9321 0800",
    ],
    [
      "Partners in Performance",
      -33.40537947493773,
      -70.5763103751507,
      1,
      "Latin America",
      "+56 9 7431 9352",
    ],
    // [
    //   "Partners in Performance",
    //   -23.5506507,
    //   -46.6333824,
    //   1,
    //   "Brazil",
    //   "+55 11 99714‑97132",
    // ],
    [
      "Partners in Performance",
      51.4762261,
      0.3897872,
      1,
      "Europe",
      "+44 207 205 4300",
    ],
    // [
    //   "Partners in Performance",
    //   25.572142,
    //   44.493308,
    //   1,
    //   "Middle East",
    //   "+971 50 677 4367",
    // ],
  ];

  function initMap(): void {
    map = new google.maps.Map(document.getElementById("map") as HTMLElement, {
      center: { lat: -33.86584575476446, lng: 151.20885264071615 },
      zoom: 2,
      mapTypeControl: false,
      styles: [
        // { elementType: "geometry", stylers: [{ color: "#242f3e" }] },
        // { elementType: "labels.text.stroke", stylers: [{ color: "#242f3e" }] },
        { elementType: "geometry", stylers: [{ color: "#d9d9d9" }] },
        { elementType: "labels.text.stroke", stylers: [{ color: "#d9d9d9" }] },
        { elementType: "labels.text.fill", stylers: [{ color: "#746855" }] },
        {
          featureType: "administrative.locality",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "poi.park",
          elementType: "geometry",
          stylers: [{ color: "#263c3f" }],
        },
        {
          featureType: "poi.park",
          elementType: "labels.text.fill",
          stylers: [{ color: "#6b9a76" }],
        },
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [{ color: "#38414e" }],
        },
        {
          featureType: "road",
          elementType: "geometry.stroke",
          stylers: [{ color: "#212a37" }],
        },
        {
          featureType: "road",
          elementType: "labels.text.fill",
          stylers: [{ color: "#9ca5b3" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry",
          stylers: [{ color: "#746855" }],
        },
        {
          featureType: "road.highway",
          elementType: "geometry.stroke",
          stylers: [{ color: "#1f2835" }],
        },
        {
          featureType: "road.highway",
          elementType: "labels.text.fill",
          stylers: [{ color: "#f3d19c" }],
        },
        {
          featureType: "transit",
          elementType: "geometry",
          stylers: [{ color: "#2f3948" }],
        },
        {
          featureType: "transit.station",
          elementType: "labels.text.fill",
          stylers: [{ color: "#d59563" }],
        },
        {
          featureType: "water",
          elementType: "geometry",
          // stylers: [{ color: "#17263c" }],
          stylers: [{ color: "#ffffff" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.fill",
          stylers: [{ color: "#515c6d" }],
        },
        {
          featureType: "water",
          elementType: "labels.text.stroke",
          // stylers: [{ color: "#17263c" }],
          stylers: [{ color: "#ffffff" }],
        },
      ],
    });
    const svgMarker = {
      path: "M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z",
      fillColor: "#1c3752",
      fillOpacity: 0.6,
      strokeWeight: 0,
      rotation: 0,
      scale: 2,
      anchor: new google.maps.Point(15, 30),
    };
    const infowindow = new google.maps.InfoWindow({
      content: "",
    });
    for (i = 0; i < locations.length; i++) {
      const marker = new google.maps.Marker({
        position: { lat: locations[i][1], lng: locations[i][2] },
        // icon: {
        //   url: "http://maps.google.com/mapfiles/ms/icons/pink-dot.png",
        // },
        icon: svgMarker,
        map,
        title: locations[i][0],
      });
      bindInfoWindow(
        marker,
        map,
        infowindow,
        "<div id='content0' className='text-white bg-dark fillOpacity testing '><img style='width:40px;height:40px' src='https://media.graphassets.com/OnvRJMgzTmyfrh6xi3uh'><br /><h5 style='color:#00e2dd' ><b>" +
          locations[i][4] +
          "</h5></b><p> <i class='fas fa-phone'></i>&nbsp; " +
          locations[i][5] +
          "</p></div>"
      );
      // marker.addListener("click", (e: any) => {
      //   console.log(e);
      //   alert("test");
      // });
    }
    function bindInfoWindow(marker, map, infowindow, html) {
      // console.log(marker);
      google.maps.event.addListener(marker, "mouseover", function () {
        infowindow.setContent(html);
        infowindow.open(map, marker);
      });
      google.maps.event.addListener(marker, "click", function () {
        infowindow.setContent(html);
        infowindow.open(map, marker);
      });
    }
    class Popup extends google.maps.OverlayView {
      position: google.maps.LatLng;
      containerDiv: HTMLDivElement;
      constructor(position: google.maps.LatLng, content: HTMLElement) {
        super();
        this.position = position;
        content.classList.add("popup-bubble");
        // This zero-height div is positioned at the bottom of the bubble.
        const bubbleAnchor = document.createElement("div");
        bubbleAnchor.classList.add("popup-bubble-anchor");
        bubbleAnchor.appendChild(content);
        // This zero-height div is positioned at the bottom of the tip.
        this.containerDiv = document.createElement("div");
        this.containerDiv.classList.add("popup-container");
        this.containerDiv.appendChild(bubbleAnchor);
        // Optionally stop clicks, etc., from bubbling up to the map.
        Popup.preventMapHitsAndGesturesFrom(this.containerDiv);
      }
      /** Called when the popup is added to the map. */
      onAdd() {
        this.getPanes()?.floatPane.appendChild(this.containerDiv);
      }
      /** Called when the popup is removed from the map. */
      onRemove() {
        if (this.containerDiv.parentElement) {
          this.containerDiv.parentElement.removeChild(this.containerDiv);
        }
      }
      /** Called each frame when the popup needs to draw itself. */
      draw() {
        const divPosition = this.getProjection().fromLatLngToDivPixel(
          this.position
        )!;
        // Hide the popup when it is far out of view.
        const display =
          Math.abs(divPosition.x) < 4000 && Math.abs(divPosition.y) < 4000
            ? "block"
            : "none";
        if (display === "block") {
          this.containerDiv.style.left = divPosition.x + "px";
          this.containerDiv.style.top = divPosition.y + "px";
        }
        if (this.containerDiv.style.display !== display) {
          this.containerDiv.style.display = display;
        }
      }
    }
    // for (i = 0; i < locations.length; i++) {
    //   popup = new Popup(
    //     // new google.maps.LatLng(locations[i][1], locations[i][1]),
    //     new google.maps.LatLng(locations[i][1] + 10, locations[i][2]),
    //     document.getElementById("content" + i) as HTMLElement
    //   );
    //   popup.setMap(map);
    // }
  }

  /**
   * A customized popup on the map.
   */

  declare global {
    interface Window {
      initMap: () => void;
    }
  }
  window.initMap = initMap;

  let i = 0;

  // let j = 0;
  // initMap();
  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      // setSubmitting(true);
      const form = e.currentTarget;
      const formData = new FormData(form);
      if (window.location.pathname.includes("wiredup-enquiry")) {
        formData.append("I have a question about?", "WiredUp");
        formData.append("LastName", "N/A");
        formData.append("Region", "N/A");
        formData.append("subject", "N/A");
        formData.append("subscription", "N/A");
      }
      const serialized: { [key: string]: FormDataEntryValue } = {};
      formData.forEach((v, k) => (serialized[k] = v));

      let submitvalue = 0;
      inputs.map(({ title, name, type, required, choices }) => {
        let box = "";
        box = document.getElementById("hidefield" + name);
        if (box != null) {
          box.style.display = "none";
        }
      });
      submitvalue = 0;
      inputs.map(({ title, name, type, required, choices }) => {
        let box = "";
        box = document.getElementById("hidefield" + name);
        const field = document.getElementById(name);
        if (field?.value == "") {
          if (box != null) {
            box.style.display = "block";
            field.focus();
            submitvalue = 1;
          }
        }
        return;
      });
      // console.log(JSON.stringify(serialized));
      if (submitvalue == 0) {
        // alert("TSET");
        setSubmitting(true);
        fetch(postUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(serialized),
        })
          .then((response) => {
            if (response.ok) {
              // void navigate("/en/thankyou?type=1");
              void navigate("/en/thankyou", {
                state: { fromContactPage: true },
              });

              // setSubmitted(true);
            } else {
              response
                .json()
                .then((result: { message: string; error?: unknown }) => {
                  const { message, error } = result;
                  setError(JSON.stringify(error) || message);
                  console.error(`Failed to send: ${message}:`, error);
                })
                .catch((error) => {
                  console.error("Error getting json", error);
                  setError(JSON.stringify(error) || "Error");
                });
            }
          })
          .catch((error: unknown) => {
            console.error(error);
            setError(JSON.stringify(error));
          })
          .finally(() => {
            setTimeout(() => {
              const element = formRef.current;
              if (element) {
                window.scrollTo(0, element.offsetTop);
              }
            }, 100);
          });
      }
    },
    [postUrl]
  );
  return (
    <>
      <section
        className={classNames(
          "bg-white-1",
          window.location.pathname.includes("contact-us/") ? "" : ""
        )}
      >
        {window.location.pathname.includes("contact-us/") && (
          <center>
            <div className="text-center p-30 col-span-6 ga-contactform-padding lg:w-5/6">
              <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2 lg:row-span-2 p2">
                <b>Interested in working with us?</b> Explore our consulting
                services and industry expertise. <br></br>
                <br></br>
                <b>Interested in working here?</b> See what a career at Partners
                in Performance looks like, or follow us on{" "}
                <Link
                  to={"https://www.linkedin.com/company/partnersinperformance/"}
                  target="_blank"
                >
                  <b className="text-purple">LinkedIn</b>{" "}
                </Link>
                and{" "}
                <Link
                  to={"https://www.instagram.com/lifeatpip/"}
                  target="_blank"
                >
                  {" "}
                  <b className="text-purple">Instagram</b>
                </Link>
                .
              </h2>
            </div>
            {/* <div className="text-center p-30 col-span-6 ga-contactform-padding lg:w-5/6">
              <p className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2 lg:row-span-2 p3">
                Please provide the following details below so we may
                appropriately assist you. We will protect your personal
                information in accordance with our Privacy Statement.
              </p>
            </div> */}
          </center>
        )}
      </section>
      <section
        data-component="Form"
        ref={formRef}
        // className="py-section relative page-grid z-10 "
        className="bg-white-1"
      >
        <Helmet>
          <script
            src="https://www.google.com/recaptcha/api.js"
            async
            defer
          ></script>
          <script
            src="https://maps.googleapis.com/maps/api/js?key=AIzaSyAayg4JFWcFuHzknjpRLGQcmJ-V3aJs1OE&callback=initMap&v=weekly"
            defer
          ></script>
          {/* <script
            src="https://kit.fontawesome.com/a076d05399.js"
            crossOrigin="anonymous"
          ></script> */}
          <script
            defer
            src="https://use.fontawesome.com/releases/v5.15.4/js/all.js"
            integrity="sha384-rOA1PnstxnOBLzCLMcre8ybwbTmemjzdNlILg8O7z1lUkLXozs4DHonlDtnE7fpc"
            crossOrigin="anonymous"
          ></script>
        </Helmet>
        <center>
          <div className="col-span-6 ga-contactform-padding lg:w-5/6">
            {submitted || error ? (
              <h2 className="col-start-2 col-span-6 h3 mt-75px">
                {error ? errorText : thankYouText}
              </h2>
            ) : (
              <>
                <div className="row page-grid lg:grid-cols-1 grid-cols-1 lg:p-30px ga-contact-from-bg">
                  <div className="relative top-30px  text-center">
                    <p className="leading-3">
                      <h2 className="col-start-2 col-span-12 h3 lg:pl-30px sm:text-center">
                        {title}
                        <br></br>
                        {tittle2}
                      </h2>
                      <br></br>
                      <span className="leading-tight">
                        <span className="ga-small-text">
                          Submit your enquiry below to help us best assist you.
                          We will protect your personal information in
                          accordance our{" "}
                          <a
                            href="/en/privacy/"
                            target="_blank"
                            className="text-dark-blue"
                          >
                            <u>privacy policy</u>.
                          </a>
                        </span>
                        <br></br>
                        <br></br>
                        <p className="mt-10px">
                          Please note all fields are mandatory.
                        </p>
                        <br></br>
                      </span>
                    </p>
                  </div>
                  <div className="w-full lg:pl-30px lg:pr-30px lg:pt-30px ">
                    <form
                      className={classNames(
                        "p-30px",
                        // "mt-60px mb-75px space-y-60px col-start-2 col-span-6 grid grid-gap grid-cols-6",
                        // " mb-50px space-y-60px col-start-2 col-span-6 grid grid-gap w-desktop-6-cols",
                        submitting && "cursor-wait"
                      )}
                      onSubmit={handleSubmit}
                    >
                      <div className="lg:grid-cols-12 grid-cols-1 page-grid">
                        {inputs.map(
                          ({ title, name, type, required, choices }) => {
                            switch (type) {
                              case "Choice":
                                i++;
                                if (i % 2 == 0) {
                                  return (
                                    <div
                                      className={classNames(
                                        "lg:col-span-6 col-span-12 pt-20px",
                                        name == "I have a question about?"
                                          ? "lg:col-span-12"
                                          : "lg:col-span-6"
                                      )}
                                    >
                                      <select
                                        key={name}
                                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                                        className={classNames(
                                          "w-full border-none"
                                        )}
                                        // className="w-full "
                                        name={name}
                                        placeholder={title}
                                        onInput={onSelectChange}
                                        value={query[name]}
                                        // required={required}
                                        id={name}
                                      >
                                        <option key="_" value="">
                                          &nbsp;{title}
                                          {/* <span>*</span> */}
                                        </option>
                                        {choices?.map((c) => (
                                          <option key={c} value={c}>
                                            &nbsp;{c}
                                          </option>
                                        ))}
                                      </select>
                                      <span
                                        className={classNames(
                                          "text-purple hidden text-left ga-font-12"
                                        )}
                                        id={"hidefield" + name}
                                      >
                                        This field is required
                                      </span>
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div
                                      className={classNames(
                                        "lg:col-span-6 col-span-12 pt-20px",
                                        name == "I have a question about?"
                                          ? "lg:col-span-12"
                                          : "lg:col-span-6"
                                      )}
                                    >
                                      <select
                                        key={name}
                                        className={classNames(
                                          "w-full col-span-12 border-none"
                                          // name == "I have a question about?"
                                          //   ? "lg:col-span-12"
                                          //   : "lg:col-span-6"
                                          // i == 1 ? "relative top-20px" : ""
                                        )}
                                        name={name}
                                        placeholder={title}
                                        onInput={onSelectChange}
                                        value={query[name]}
                                        // required={required}
                                        id={name}
                                      >
                                        <option key="_" value="">
                                          {title}
                                        </option>
                                        {choices?.map((c) => (
                                          <option key={c} value={c}>
                                            &nbsp;{c}
                                          </option>
                                        ))}
                                      </select>
                                      <span
                                        className={classNames(
                                          "text-purple hidden text-left ga-font-12"
                                        )}
                                        id={"hidefield" + name}
                                      >
                                        This field is required
                                      </span>
                                    </div>
                                  );
                                }

                              case "Message":
                                return (
                                  <div className="col-span-12 pt-20px">
                                    <textarea
                                      key={name}
                                      className="w-full border-none"
                                      // className="col-span-1 col-span-6 md:col-start-1 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0 mt-60px"
                                      name={name}
                                      rows={6}
                                      // required={required}
                                      placeholder={title}
                                      spellCheck={true}
                                      onChange={onChange}
                                      value={query[name]}
                                      id={name}
                                    />
                                    <span
                                      className={classNames(
                                        "text-purple hidden text-left ga-font-12"
                                      )}
                                      id={"hidefield" + name}
                                    >
                                      This field is required
                                    </span>
                                  </div>
                                );
                              default:
                                i++;
                                if (i % 2 == 0) {
                                  return (
                                    <div
                                      className={classNames(
                                        "pt-20px col-span-12",
                                        name == "Company"
                                          ? "lg:col-span-6"
                                          : "lg:col-span-6",
                                        name == "subject"
                                          ? "lg:col-span-6"
                                          : "lg:col-span-6"
                                      )}
                                    >
                                      <input
                                        key={name}
                                        // className="col-span-1 col-span-6  md:col-start-4 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0"
                                        className="w-full border-none"
                                        type={buildInputType(type)}
                                        name={name}
                                        // required={required}
                                        placeholder={title}
                                        onChange={onChange}
                                        value={query[name]}
                                        id={name}
                                      />
                                      <span
                                        className={classNames(
                                          "text-purple hidden text-left ga-font-12"
                                        )}
                                        id={"hidefield" + name}
                                      >
                                        This field is required
                                      </span>
                                    </div>
                                  );
                                } else {
                                  if (name == "subject") {
                                    return (
                                      <div className="lg:col-span-12 col-span-12 pt-20px border-none">
                                        <input
                                          key={name}
                                          className="w-full"
                                          type={buildInputType(type)}
                                          name={name}
                                          // required={required}
                                          placeholder={title}
                                          onChange={onChange}
                                          value={query[name]}
                                          id={name}
                                        />
                                        <span
                                          className={classNames(
                                            "text-purple hidden text-left ga-font-12 "
                                          )}
                                          id={"hidefield" + name}
                                        >
                                          This field is required
                                        </span>
                                      </div>
                                    );
                                  } else {
                                    return (
                                      <div
                                        className={classNames(
                                          "pt-20px col-span-12 ",
                                          name == "Company"
                                            ? "lg:col-span-6"
                                            : "lg:col-span-6"
                                        )}
                                      >
                                        <input
                                          key={name}
                                          className="w-full border-none"
                                          // className="col-span-1 col-span-6 md:col-start-1 md:col-span-3 border-0 border-b-2 border-dark-blue focus:ring-0 p-0 mt-60px"
                                          type={buildInputType(type)}
                                          name={name}
                                          // required={required}
                                          placeholder={title}
                                          onChange={onChange}
                                          value={query[name]}
                                          id={name}
                                        />
                                        <span
                                          className={classNames(
                                            "text-purple hidden text-left ga-font-12"
                                          )}
                                          id={"hidefield" + name}
                                        >
                                          This field is required
                                        </span>
                                      </div>
                                    );
                                  }
                                }
                            }
                          }
                        )}
                      </div>
                      <div className="lg:grid-cols-12 grid-cols-1 page-grid">
                        {window.location.pathname.includes("contact-us/") && (
                          <div
                            className={classNames(
                              "lg:col-span-6 col-span-12 mt-10px text-left",
                              window.location.pathname.includes("contact-us/")
                                ? ""
                                : "hidden"
                            )}
                          >
                            <div className="col-span-1 col-span-6 md:col-start-1 md:col-span-3 relative">
                              <b>
                                Do you want to stay up to date with the latest
                                industry news, research and analysis?
                              </b>
                              {/* <div className="p-10px ">
                                <div className="text-left">
                                  <p className="m-auto">
                                    <input
                                      type="checkbox"
                                      name="suscription"
                                      checked
                                    />
                                    &nbsp;&nbsp;&nbsp;Yes, I’d like to receive
                                    updates from Performance. You can withdraw
                                    anytime.
                                  </p>
                                </div>
                              </div> */}
                            </div>
                            <div className="w-full mt-15px text-14">
                              <p className="m-auto">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  name="subscription"
                                  defaultChecked
                                />
                                &nbsp;&nbsp;By checking this box, you agree to
                                receive updates from Partners in Performance and
                                acknowledge you have read our{" "}
                                <a href="/en/privacy/">
                                  <u>privacy policy</u>
                                </a>
                                &nbsp;. You can opt out at any time.
                              </p>
                            </div>
                          </div>
                        )}
                        {!window.location.pathname.includes("contact-us/") && (
                          <div
                            className={classNames(
                              "lg:col-span-6 col-span-12 mt-10px text-left"
                            )}
                          ></div>
                        )}
                        <div className="lg:col-span-6 col-span-12 items-center mt-10px">
                          {/* <center> */}
                          {/* <div
                            className="g-recaptcha col-span-1 col-span-6 md:col-start-1 md:col-span-3 relative float-right mb-20px"
                            data-sitekey="6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI"
                          ></div> */}
                          <div className="w-full mt-15px">
                            <button
                              type="submit"
                              disabled={submitting || disabled}
                              className="col-span-1 col-span-6 md:col-start-1 md:col-span-3  p-0 grid ga-card-button w-full ga-max-width-300px float-right"
                            >
                              {/* <div className="bg-right-pattern bg-right bg-no-repeat bg-28px h-28px"></div> */}
                              {/* <span className="self-center text-center read-more"> */}
                              {buttonText}
                              {/* </span> */}
                            </button>
                          </div>
                          {/* </center> */}
                          {/* <center>
                            <button
                              type="submit"
                              disabled={submitting || disabled}
                              className="col-span-1 col-span-6 md:col-start-1 md:col-span-3  p-0 mt-60px grid grid-gap grid-cols-2 ga-card-button"
                            >
                              <div className="bg-right-pattern bg-right bg-no-repeat bg-28px h-28px"></div>
                              <span className="self-center text-center read-more">
                                {buttonText}
                              </span>
                            </button>
                          </center> */}
                        </div>
                      </div>
                      {/* <div className="col-span-12 lg:mt-0 mt-48px">
                        <center>
                          <button
                            type="submit"
                            disabled={submitting || disabled}
                            className="col-span-1 col-span-6 md:col-start-1 md:col-span-3  p-0 grid ga-card-button"
                          >
                            {buttonText}
                          </button>
                        </center>
                      </div> */}
                    </form>
                  </div>
                  <div
                    className={classNames(
                      "w-full relative lg:pl-30px lg:pr-30px lg:pb-30px sm:top-20px",
                      window.location.pathname.includes("contact-us/")
                        ? ""
                        : "hidden"
                    )}
                  >
                    {/* <h3>My Google Maps Demo</h3>
                <div id="map"></div> */}
                    {/* <iframe
                  width="100%"
                  height="65%"
                  frameBorder="0"
                  scrolling="no"
                  className="relative w-full lg:p-30px"
                  // src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=-33.86700,%20151.20770+(PIP%20Global)&amp;t=&amp;z=16&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  src="https://www.google.com/maps/d/embed?mid=174aMourGLokkUb2CGrHFZpUYksBCtWI&ehbc=2E312F&z=1.9"
                  // src="https://www.google.com/maps/d/embed?mid=1rp26VycrgK8SUGCSUa06qLlaFW_oc7E&ehbc=2E312F"
                >
                  <a href="https://www.maps.ie/distance-area-calculator.html">
                    measure acres/hectares on map
                  </a>
                </iframe> */}
                    <h3 className="h3 pb-20px">Our offices</h3>
                    <div id="map" className="googlemap"></div>
                    {/* <div
                      id="content0"
                      className="text-white bg-dark fillOpacity testing "
                      data-value="0"
                    >
                      <h1>
                        <b>Partners in Performance</b>
                      </h1>{" "}
                      <br />
                      <p>Africa</p>
                      <p> ph:+27 11 463 0903</p>
                    </div>
                    <div id="content1" className="testing" data-value="1">
                      <h1>
                        <b>Partners in Performance</b>
                      </h1>{" "}
                      <br />
                      <p>Australia and New Zealand</p>
                      <p> ph:+61 2 9321 0800</p>
                    </div>
                    <div id="content2" className="testing" data-value="2">
                      <h1>
                        <b>Partners in Performance</b>
                      </h1>{" "}
                      <br />
                      <p>USA</p>
                      <p> ph:+56 9 50086555</p>
                    </div>
                    <div id="content3" className="testing" data-value="3">
                      <h1>
                        <b>Partners in Performance</b>
                      </h1>{" "}
                      <br />
                      <p>New Zealand</p>
                      <p> ph:+61 2 9321 0800</p>
                    </div> */}
                  </div>
                </div>
              </>
            )}
          </div>
        </center>
      </section>
    </>
  );
};
