/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import { graphql } from "gatsby";
import React from "react";
import { GraphCms_GenericContentBlock } from "../../generated/graphql-types";
import { TestimonalsContentBlock } from "./TestimonialsContentBlock";

export const ContentBlock: React.FC<{
  block: GraphCms_GenericContentBlock;
}> = ({ block }) => {
  const subtitle = getCleanedRTF(block.subtitle);
  const content = getCleanedRTF(block.content);
  const title = block.showTitle ? block.title : undefined;

  switch (block.contentType) {
    case "Testimonials":
      return (
        <TestimonalsContentBlock
          title={title}
          subtitle={subtitle}
          content={content}
        />
      );
  }
  return (
    <pre>No support for Generic Content block of type {block.contentType}</pre>
  );
};

export const ContentBlockFragment = graphql`
  fragment ContentBlockFragment on GraphCMS_GenericContentBlock {
    id
    title
    showTitle
    contentType
    subtitle {
      cleaned
    }
    content {
      cleaned
    }
    visual {
      ...LottieFragment
      ...ImageFragment
      ...SvgFragment
      ...VideoFragment
    }
    previewImage {
      ...ImageFragment
    }
    loop
  }
`;
