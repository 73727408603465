import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
import { createScroller } from "./Scroller";

interface Props {
  id: string;
  contents: React.ReactElement[];
  className?: string;
  title?: string;
}

export const HillsAndValleysCarousel: React.FC<Props> = ({
  id,
  contents,
  className,
  title,
}) => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const ref = sectionRef.current;
    if (ref) {
      const state = createScroller(ref);
      return state.destroy;
    }
  }, []);

  return (
    <section
      data-component="Hills And Valleys Carousel"
      className={classNames(
        "relative w-full page-grid max-width z-10 ",
        className ? className : "py-section"
      )}
      ref={sectionRef}
    >
      {title && (
        <h2
          className={classNames(
            "h7 col-start-2 col-span-12 text-center leading-tight",
            "pb-section row-start-1"
          )}
        >
          {title}
        </h2>
      )}
      {/* {contents.length > 4 && (
        <button className="prev-button col-start-2 row-start-3 lg:row-start-2 lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center">
          <img src={leftIcon} alt="Left" />
        </button>
      )} */}
      <button
        className={classNames(
          "prev-button col-start-2 row-start-3 lg:row-start-2 lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center",
          contents.length > 4 ? "md:block" : "md:hidden block"
        )}
      >
        <img src={leftIcon} alt="Left" />
      </button>
      <div className="viewport col-start-1 col-span-14 md:col-start-2 md:col-span-12 relative">
        <div className="scroll-container relative top-0 left-0 bottom-0 right-0 w-full flex grid-gap overflow-scroll hide-scrollbar">
          {contents.map((article, articleIndex) => {
            const odd = articleIndex % 2 === 1;
            const fullId = `${id}-${articleIndex}`;

            return (
              <div
                key={fullId}
                id={fullId}
                className={classNames(
                  "md:w-tablet-4-cols lg:w-desktop-3-cols relative flex-grow-0 flex-shrink-0 w-full pl-10px pr-10px"
                )}
              >
                <div
                  className={classNames(
                    "w-full",
                    odd ? "pt-100px xl:pt-145px" : "pb-100px xl:pb-100px"
                  )}
                >
                  {article}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <button
        className={classNames(
          "next-button col-start-13 row-start-3 lg:row-start-2 lg:col-start-14 lg:col-span-1 h-full justify-self-end lg:justify-self-center",
          contents.length > 4 ? "md:block" : "md:hidden block"
        )}
      >
        <img src={rightIcon} alt="Right" />
      </button>
    </section>
  );
};
