import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import { createScroller } from "./Scroller";

interface Props {
  id: string;
  contents: React.ReactElement[];
  title?: string;
}

export const WideCarousel: React.FC<Props> = ({ contents, title }) => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const ref = sectionRef.current;
    if (ref) {
      const state = createScroller(ref);
      return state.destroy;
    }
  }, []);

  return (
    <section
      data-component="Wide Carousel"
      className="relative page-grid max-width py-section z-10 overflow-hidden"
      ref={sectionRef}
    >
      {title && (
        <h2
          className={classNames(
            "h2 col-start-2 col-span-12 text-center row-start-1",
            "pb-section"
          )}
        >
          {title}
        </h2>
      )}

      <div className="absolute w-full h-full bottom-0 left-0 bg-gradient-to-t from-light-blue transform-gpu translate-y-4/12" />
      <div className="viewport col-start-1 col-span-full row-start-2 row-span-1 relative max-width">
        <div className="scroll-container relative top-0 left-0 bottom-0 right-0 w-full flex grid-gap overflow-scroll hide-scrollbar">
          {contents}
        </div>
      </div>
    </section>
  );
};
