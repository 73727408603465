/* eslint-disable no-empty */
import { GatsbyLinkProps, Link as GatsbyLink } from "gatsby";
import React from "react";
import { getLink } from ".";
import {
  GraphCms_MenuEntry,
  GraphCms_PageLink,
} from "../generated/graphql-types";

export const I18Link: React.FC<Omit<GatsbyLinkProps<unknown>, "ref">> = ({
  to,
  ...props
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const localevalue = "en";
  // if (path.toLowerCase().search("/en") == -1) {
  //   if (path.toLowerCase().search("/pt_br") == -1) {
  //   } else {
  //     localevalue = "pt_BR";
  //   }
  // } else {
  //   localevalue = "en";
  // }
  const realTo = `/${localevalue}${to}`;
  return <GatsbyLink {...props} to={realTo} />;
};

const SingleInternalLink: React.FC<{
  name: string;
  pageLink: GraphCms_PageLink;
}> = ({ name, pageLink }) => {
  const link = getLink(pageLink);
  return <I18Link to={link}>{name}</I18Link>;
};

const SingleLink: React.FC<{ link: GraphCms_MenuEntry }> = ({
  link: { name, externalUrl, pageLink, assetToDownload },
}) => {
  if (pageLink) {
    return <SingleInternalLink name={name} pageLink={pageLink} />;
  }
  if (externalUrl) {
    return <a href={externalUrl}>{name}</a>;
  }
  const url = assetToDownload?.localFile?.publicURL;
  if (url) {
    return (
      <a href={url} target="_blank" rel="noreferrer">
        {name}
      </a>
    );
  }

  return null;
};

export const Links: React.FC<{ links?: ReadonlyArray<GraphCms_MenuEntry> }> = ({
  links,
}) => {
  if (!links || links.length === 0) {
    return null;
  }

  return (
    <section data-component="Links" className={"relative w-full max-width"}>
      <div className="relative w-full page-grid text-dark-blue py-48px">
        <div className="col-start-2 col-span-12 flex flex-wrap gap-y-10px  gap-x-48px underline">
          {links.map((link) => (
            <SingleLink link={link} key={link.id} />
          ))}
        </div>
      </div>
    </section>
  );
};
