/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/alt-text */
import classNames from "classnames";
import React, { Fragment } from "react";
import { Dots } from ".";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";

export const ValuesCollection: React.FC<{
  className?: string;
  title: string;
  heading?: CleanedRTF | undefined;
  changeDesign?: string;
  styleclass?: string;
  butlletdesign?: string;
  statements?: {
    id: string;
    title: string | undefined;
    heading: CleanedRTF | undefined;
    subhead?: string;
    designclass?: string;
  }[];
}> = ({
  className,
  title,
  heading,
  statements,
  changeDesign,
  styleclass,
  butlletdesign,
}) => {
  const rightClassName = classNames(
    "text-right-margin space-y-20px",
    "col-start-2 col-span-12 mt-20px",
    "lg:col-start-7 lg:col-span-7 lg:mt-0"
  );

  let i = 0;
  if (changeDesign == "YES") {
    return (
      <section
        data-component="Values Collection"
        className={classNames(
          "full-width max-width relative z-10 bg-gradient-to-t via-light-blue",
          className
        )}
      >
        <div
          className={classNames(
            "relative w-full page-grid max-width ",
            statements?.length == 1 ? "pt-10px pb-48px " : "pt-48px pb-48px",
            styleclass
          )}
        >
          <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
            {title}
          </h2>
          {heading && (
            <>
              <RTF
                content={heading}
                // className={classNames(rightClassName, "mb-40px")}
                className={classNames(rightClassName, "mb-20px")}
                classNameOverrides={{ p: "p3" }}
              />
              {butlletdesign && (
                <div
                  className={classNames(
                    rightClassName,
                    "border-b border-dark-blue row-start-2"
                  )}
                />
              )}
            </>
          )}
          {statements && butlletdesign && (
            <div className={classNames(rightClassName, "pl-10px")}>
              <ul className="mt-15px">
                {statements.map(({ id, title, heading }) => {
                  const hasRTF = !!heading;
                  i = 1 + i;
                  return (
                    <Fragment key={id}>
                      <li
                        className={classNames(
                          statements.length == 1 ? "" : "list-disc ml-10px"
                        )}
                      >
                        <h3
                          className={classNames(
                            rightClassName,
                            // hasRTF ? "p2" : "p3",
                            i == 1 ? "" : "pt-20px"
                          )}
                        >
                          {title}
                        </h3>
                        {hasRTF && (
                          <RTF
                            content={heading}
                            className={classNames(rightClassName)}
                            classNameOverrides={{ p: "p3" }}
                          />
                        )}
                      </li>
                    </Fragment>
                  );
                })}
              </ul>
            </div>
          )}
          {statements && !butlletdesign && (
            <>
              {heading && (
                <div
                  className={classNames(
                    rightClassName,
                    "border-b border-dark-blue row-start-2"
                  )}
                />
              )}
              <div
                className={classNames(
                  rightClassName,
                  !heading ? "" : "row-start-3 pt-15px"
                )}
              >
                <ul>
                  {statements.map(({ id, title, heading }) => {
                    const hasRTF = !!heading;
                    return (
                      <Fragment key={id}>
                        <li className="">
                          <h3
                            className={classNames(
                              rightClassName,
                              hasRTF ? "p2" : "p3",
                              "pt-20px"
                            )}
                          >
                            {title}
                          </h3>
                          {hasRTF && (
                            <RTF
                              content={heading}
                              className={classNames(rightClassName)}
                              classNameOverrides={{ p: "p3" }}
                            />
                          )}
                        </li>
                      </Fragment>
                    );
                  })}
                </ul>
              </div>
            </>
          )}
          {/* <div className={classNames(rightClassName, "row-start-3 pt-40px")}>
            <ul>
              {statements.map(({ id, title, heading }) => {
                const hasRTF = !!heading;
                return (
                  <Fragment key={id}>
                    <li className="">
                      <h3
                        className={classNames(
                          rightClassName,
                          hasRTF ? "p2" : "p3",
                          "pt-20px"
                        )}
                      >
                        {title}
                      </h3>
                      {hasRTF && (
                        <RTF
                          content={heading}
                          className={classNames(rightClassName)}
                          classNameOverrides={{ p: "p3" }}
                        />
                      )}
                    </li>
                  </Fragment>
                );
              })}
            </ul>
          </div> */}
          {/* <Dots
            type="White"
            className={classNames(
              "hidden lg:block col-start-5 col-span-2",
              "row-start-2",
              "opacity-75"
            )}
            translate="scale-75"
            inFront={true}
          /> */}
        </div>
      </section>
    );
  } else {
    return (
      <section
        data-component="Values Collection"
        className={classNames(
          "full-width max-width relative z-10 bg-gradient-to-t from-light-blue via-light-blue",
          className
        )}
      >
        <div className="relative w-full page-grid max-width py-section">
          <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
            {title}
          </h2>
          {heading && (
            <RTF
              content={heading}
              className={classNames(rightClassName, "mb-40px")}
              classNameOverrides={{ p: "p3" }}
            />
          )}
          {statements && butlletdesign && (
            <div className={classNames(rightClassName, "pl-10px")}>
              <ul>
                {statements.map(
                  ({ id, title, heading, subhead, designclass }) => {
                    const hasRTF = !!heading;
                    i = 1 + i;
                    return (
                      <Fragment key={id}>
                        {subhead && (
                          <li>
                            <h3
                              className={classNames(
                                rightClassName,
                                hasRTF ? "p2" : "p3",
                                i == 1 ? "" : "pt-20px",
                                designclass
                              )}
                            >
                              {title}
                            </h3>
                            {hasRTF && (
                              <RTF
                                content={heading}
                                className={classNames(rightClassName)}
                                classNameOverrides={{ p: "p3" }}
                              />
                            )}
                          </li>
                        )}
                        {!subhead && (
                          <li className="list-disc ml-10px">
                            <h3
                              className={classNames(
                                rightClassName,
                                hasRTF ? "p2" : "p3",
                                i == 1 ? "" : "pt-20px"
                              )}
                            >
                              {title}
                            </h3>
                            {hasRTF && (
                              <RTF
                                content={heading}
                                className={classNames(rightClassName)}
                                classNameOverrides={{ p: "p3" }}
                              />
                            )}
                          </li>
                        )}
                      </Fragment>
                    );
                  }
                )}
              </ul>
            </div>
          )}
          {statements && !butlletdesign && (
            <>
              <div
                className={classNames(
                  rightClassName,
                  "border-b border-dark-blue row-start-2"
                )}
              />
              <div
                className={classNames(rightClassName, "row-start-3 pt-40px")}
              >
                {statements.map(({ id, title, heading }) => {
                  const hasRTF = !!heading;

                  return (
                    <Fragment key={id}>
                      <h3
                        className={classNames(
                          rightClassName,
                          hasRTF ? "p2" : "p3",
                          "pt-20px"
                        )}
                      >
                        {title}
                      </h3>
                      {hasRTF && (
                        <RTF
                          content={heading}
                          className={classNames(rightClassName)}
                          classNameOverrides={{ p: "p3" }}
                        />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </>
          )}
          {statements && (
            <Dots
              type="White"
              className={classNames(
                "hidden lg:block col-start-5 col-span-2",
                "row-start-2",
                "opacity-75"
              )}
              translate="scale-75"
              inFront={true}
            />
          )}
          {butlletdesign == "Need" && (
            <Dots
              type="White"
              className={classNames(
                "hidden lg:block col-start-5 col-span-2",
                "row-start-2",
                "opacity-75 ga-top--100px"
              )}
              translate="scale-75"
              inFront={true}
            />
          )}
        </div>
      </section>
    );
  }
};
