import {
  AutoVisualNoLottie,
  getVisual,
  VisualAsset,
} from "@bond-london/gatsby-graphcms-components";
import { CustomEmbedRendererProps } from "@bond-london/graphcms-rich-text";
import React from "react";
import { GraphCms_Asset } from "../generated/graphql-types";

const ImageAssetRendererInside: React.FC<{
  visual: VisualAsset;
  isInline?: boolean;
}> = ({ visual, isInline }) => {
  return (
    <AutoVisualNoLottie
      visual={visual}
      style={
        isInline
          ? {
              display: "inline-flex",
              verticalAlign: "middle",
            }
          : undefined
      }
      className={isInline ? "mx-xxs laptop:mx-xs" : "mb-s block"}
    />
  );
};
export type ImageAssetRendererProps = CustomEmbedRendererProps<GraphCms_Asset>;
export const ImageAssetRenderer: React.FC<ImageAssetRendererProps> = (
  props
) => {
  const visual = getVisual(props);
  if (!visual) {
    return <pre>No visual</pre>;
  }
  if (props.linkUrl) {
    return (
      <a href={props.linkUrl} target="_blank" rel="noreferrer">
        <ImageAssetRendererInside visual={visual} isInline={props.isInline} />
      </a>
    );
  }
  return <ImageAssetRendererInside visual={visual} isInline={props.isInline} />;
};
