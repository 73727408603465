// /* eslint-disable jsx-a11y/heading-has-content */
// /* eslint-disable react/jsx-key */
// /* eslint-disable @typescript-eslint/no-unsafe-assignment */
// /* eslint-disable jsx-a11y/iframe-has-title */
// /* eslint-disable no-constant-condition */
// /* eslint-disable jsx-a11y/media-has-caption */
// /* eslint-disable @typescript-eslint/no-unsafe-member-access */
// import { CleanedRTF } from "@bond-london/graphcms-rich-text";
// import classNames from "classnames";
// import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
// import React, { FC, Fragment } from "react";
// import { RTF } from "../elements";
// // import { ValuesCollection } from "./ValuesCollection";
// import video1 from "../images/door-hope.mp4";
// export interface ArticleInformation {
//   id: string;
//   image?: IGatsbyImageData;
//   alt?: string;
//   side?: "left" | "right";
//   title?: string;
//   tagline?: string;
//   detailsRTF?: CleanedRTF;
//   link?: string;
//   linkText?: string;
//   articlewithContent?: string;
//   statements?: {
//     id: string;
//     title: string | undefined;
//     heading: CleanedRTF | undefined;
//   }[];
//   heading?: CleanedRTF | undefined;
//   Articletitle?: string;
//   video?: string;
//   listing?: string[];
//   afterlisting?: string;
//   smallfont?: string;
// }

// export const Article: FC<ArticleInformation> = ({
//   image,
//   alt,
//   side,
//   title,
//   tagline,
//   detailsRTF,
//   link,
//   linkText,
//   articlewithContent,
//   statements,
//   heading,
//   Articletitle,
//   video,
//   listing,
//   afterlisting,
//   smallfont,
// }) => {
//   if (typeof window === "undefined" || !window.document) {
//     return;
//   }
//   const path = window.location.pathname;
//   const rightClassName = classNames(
//     "text-right-margin space-y-20px",
//     "col-start-2 col-span-12 mt-20px",
//     "lg:col-start-7 lg:col-span-7 lg:mt-0"
//   );
//   // return (
//   //   <section
//   //     data-component="Article"
//   //     className="z-10 py-section relative page-grid"
//   //   >
//   //     {image && (
//   //       <div
//   //         className={classNames(
//   //           "aspect-w-4 aspect-h-3",
//   //           "lg:row-start-1 lg:row-span-1",
//   //           "col-start-2 col-span-12",
//   //           side === "right"
//   //             ? "lg:col-start-2 lg:col-span-6"
//   //             : "lg:col-start-8 lg:col-span-6"
//   //         )}
//   //       >
//   //         <GatsbyImage
//   //           style={{ position: "absolute" }}
//   //           className={classNames()}
//   //           imgStyle={{
//   //             objectFit: "cover",
//   //             objectPosition: "center",
//   //           }}
//   //           image={image}
//   //           alt={alt || title || tagline || "image"}
//   //         />
//   //       </div>
//   //     )}
//   //     {articlewithContent == "YES" && (
//   //       <div
//   //         className={classNames(
//   //           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //           "self-center",
//   //           side === "right"
//   //             ? "lg:col-start-9 lg:col-span-5"
//   //             : "lg:col-start-2 lg:col-span-5"
//   //         )}
//   //       >
//   //         <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
//   //           {Articletitle}
//   //         </h2>
//   //         <div
//   //           className={classNames(
//   //             // "relative w-full max-width py-section"
//   //             "relative w-full max-width mt-15px"
//   //             // styleclass
//   //           )}
//   //         >
//   //           {heading && (
//   //             <RTF
//   //               content={heading}
//   //               className={classNames(rightClassName, "mb-40px")}
//   //               classNameOverrides={{ p: "p3" }}
//   //             />
//   //           )}
//   //           {statements && (
//   //             <div
//   //               className={classNames(
//   //                 rightClassName,
//   //                 "border-b border-dark-blue row-start-2 mb-30px"
//   //               )}
//   //             />
//   //           )}
//   //           {statements && (
//   //             <div className={classNames(rightClassName, "row-start-3 ")}>
//   //               {statements.map(({ id, title, heading }) => {
//   //                 const hasRTF = !!heading;
//   //                 return (
//   //                   <Fragment key={id}>
//   //                     <h3
//   //                       className={classNames(
//   //                         rightClassName,
//   //                         !listing || smallfont != "YES"
//   //                           ? hasRTF
//   //                             ? "p2"
//   //                             : "p3"
//   //                           : "",
//   //                         "pt-20px"
//   //                       )}
//   //                     >
//   //                       {title}
//   //                     </h3>
//   //                     {hasRTF && (
//   //                       <RTF
//   //                         content={heading}
//   //                         className={classNames(rightClassName)}
//   //                         classNameOverrides={{ p: "p3" }}
//   //                       />
//   //                     )}
//   //                   </Fragment>
//   //                 );
//   //               })}
//   //             </div>
//   //           )}
//   //           {listing && (
//   //             <div className="p-10px">
//   //               {listing.map((t, index) => {
//   //                 return <li className="mt-10px mb-10px">{t}</li>;
//   //               })}
//   //             </div>
//   //           )}
//   //           {afterlisting && (
//   //             <h3 className={classNames(rightClassName, "", "pt-20px")}>
//   //               {afterlisting}
//   //             </h3>
//   //           )}
//   //         </div>
//   //         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//   //         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //         {link && linkText && (
//   //           <a
//   //             href={link}
//   //             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //           >
//   //             {linkText}
//   //           </a>
//   //         )}
//   //       </div>
//   //     )}
//   //     {!articlewithContent && (
//   //       <div
//   //         className={classNames(
//   //           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //           "self-center",
//   //           side === "right"
//   //             ? "lg:col-start-9 lg:col-span-5"
//   //             : "lg:col-start-2 lg:col-span-5"
//   //         )}
//   //       >
//   //         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//   //         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //         {link && linkText && (
//   //           <a
//   //             href={link}
//   //             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //           >
//   //             {linkText}
//   //           </a>
//   //         )}
//   //       </div>
//   //     )}
//   //   </section>
//   // );

//   return (
//     <section
//       data-component="Article"
//       className="z-10 py-section relative page-grid"
//     >
//       {image && (
//         <div
//           className={classNames(
//             "aspect-w-4 aspect-h-3",
//             "lg:row-start-1 lg:row-span-1",
//             "col-start-2 col-span-12",
//             side === "right"
//               ? "lg:col-start-2 lg:col-span-6"
//               : "lg:col-start-8 lg:col-span-6"
//           )}
//         >
//           <GatsbyImage
//             style={{ position: "absolute" }}
//             className={classNames()}
//             imgStyle={{
//               objectFit: "cover",
//               objectPosition: "center",
//             }}
//             image={image}
//             alt={alt || title || tagline || "image"}
//           />
//         </div>
//       )}
//       <div
//         className={classNames(
//           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//           "self-center",
//           side === "right"
//             ? "lg:col-start-9 lg:col-span-5"
//             : "lg:col-start-2 lg:col-span-5"
//         )}
//       >
//         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//         {link && linkText && (
//           <a
//             href={link}
//             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//           >
//             {linkText}
//           </a>
//         )}
//       </div>
//     </section>
//   );

//   // if (articlewithContent == "YES") {
//   //   const rightClassName = classNames(
//   //     "text-right-margin space-y-20px",
//   //     "col-start-2 col-span-12 mt-20px",
//   //     "lg:col-start-7 lg:col-span-7 lg:mt-0"
//   //   );
//   //   // return (
//   //   //   <section
//   //   //     data-component="Article"
//   //   //     className="z-10 py-section relative page-grid"
//   //   //   >
//   //   //     {image && !video && (
//   //   //       <div
//   //   //         className={classNames(
//   //   //           "aspect-w-4 aspect-h-3",
//   //   //           "lg:row-start-1 lg:row-span-1",
//   //   //           "col-start-2 col-span-12 mb-30px",
//   //   //           side === "right"
//   //   //             ? "lg:col-start-2 lg:col-span-6"
//   //   //             : "lg:col-start-8 lg:col-span-6"
//   //   //         )}
//   //   //       >
//   //   //         <GatsbyImage
//   //   //           style={{ position: "absolute" }}
//   //   //           className={classNames()}
//   //   //           imgStyle={{
//   //   //             objectFit: "cover",
//   //   //             objectPosition: "center",
//   //   //           }}
//   //   //           image={image}
//   //   //           alt={alt || title || tagline || "image"}
//   //   //         />
//   //   //       </div>
//   //   //     )}
//   //   //     {video && (
//   //   //       <div
//   //   //         className={classNames(
//   //   //           "aspect-w-4 aspect-h-3",
//   //   //           "lg:row-start-1 lg:row-span-1",
//   //   //           "col-start-2 col-span-12 mb-20px",
//   //   //           side === "right"
//   //   //             ? "lg:col-start-2 lg:col-span-6"
//   //   //             : "lg:col-start-8 lg:col-span-6"
//   //   //         )}
//   //   //       >
//   //   //         {/* <video src="https://www.veed.io/view/34eb129f-0d65-44b2-b288-14a2a168e32b?source=compressor-sharing"  width=100/> */}
//   //   //         {/* <GatsbyImage
//   //   //           style={{ position: "absolute" }}
//   //   //           className={classNames()}
//   //   //           imgStyle={{
//   //   //             objectFit: "cover",
//   //   //             objectPosition: "center",
//   //   //           }}
//   //   //           image={image}
//   //   //           alt={alt || title || tagline || "image"}
//   //   //         /> */}
//   //   //         <video
//   //   //           // autoPlay={true}
//   //   //           // muted={true}
//   //   //           // loop={true}
//   //   //           controls
//   //   //           src={video1}
//   //   //           className={classNames(
//   //   //             "absolute h-full w-full top-0 left-0 right-0 bottom-0",
//   //   //             1 == 1 ? "object-center" : "object-right md:object-left"
//   //   //             // "object-cover"
//   //   //           )}
//   //   //         />
//   //   //       </div>
//   //   //     )}
//   //   //     <div
//   //   //       className={classNames(
//   //   //         "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //   //         "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //   //         "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //   //         "self-center",
//   //   //         side === "right"
//   //   //           ? "lg:col-start-9 lg:col-span-5"
//   //   //           : "lg:col-start-2 lg:col-span-5"
//   //   //       )}
//   //   //     >
//   //   //       <section
//   //   //         data-component="Values Collection"
//   //   //         className={classNames(
//   //   //           "full-width max-width relative z-10 bg-gradient-to-t via-light-blue"
//   //   //           // className
//   //   //         )}
//   //   //       >
//   //   //         <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
//   //   //           {Articletitle}
//   //   //         </h2>
//   //   //         <div
//   //   //           className={classNames(
//   //   //             // "relative w-full max-width py-section"
//   //   //             "relative w-full max-width mt-15px"
//   //   //             // styleclass
//   //   //           )}
//   //   //         >
//   //   //           {heading && (
//   //   //             <RTF
//   //   //               content={heading}
//   //   //               className={classNames(rightClassName, "mb-40px")}
//   //   //               classNameOverrides={{ p: "p3" }}
//   //   //             />
//   //   //           )}
//   //   //           {statements && (
//   //   //             <div
//   //   //               className={classNames(
//   //   //                 rightClassName,
//   //   //                 "border-b border-dark-blue row-start-2 mb-30px"
//   //   //               )}
//   //   //             />
//   //   //           )}
//   //   //           {statements && (
//   //   //             <div className={classNames(rightClassName, "row-start-3 ")}>
//   //   //               {statements.map(({ id, title, heading }) => {
//   //   //                 const hasRTF = !!heading;
//   //   //                 return (
//   //   //                   <Fragment key={id}>
//   //   //                     <h3
//   //   //                       className={classNames(
//   //   //                         rightClassName,
//   //   //                         !listing || smallfont != "YES"
//   //   //                           ? hasRTF
//   //   //                             ? "p2"
//   //   //                             : "p3"
//   //   //                           : "",
//   //   //                         "pt-20px"
//   //   //                       )}
//   //   //                     >
//   //   //                       {title}
//   //   //                     </h3>
//   //   //                     {hasRTF && (
//   //   //                       <RTF
//   //   //                         content={heading}
//   //   //                         className={classNames(rightClassName)}
//   //   //                         classNameOverrides={{ p: "p3" }}
//   //   //                       />
//   //   //                     )}
//   //   //                   </Fragment>
//   //   //                 );
//   //   //               })}
//   //   //             </div>
//   //   //           )}
//   //   //           {listing && (
//   //   //             <div className="p-10px">
//   //   //               {listing.map((t, index) => {
//   //   //                 return <li className="mt-10px mb-10px">{t}</li>;
//   //   //               })}
//   //   //             </div>
//   //   //           )}
//   //   //           {afterlisting && (
//   //   //             <h3 className={classNames(rightClassName, "", "pt-20px")}>
//   //   //               {afterlisting}
//   //   //             </h3>
//   //   //           )}
//   //   //           {/* <Dots
//   //   //             type="White"
//   //   //             className={classNames(
//   //   //               "hidden lg:block col-start-5 col-span-2",
//   //   //               "row-start-2",
//   //   //               "opacity-75"
//   //   //             )}
//   //   //             translate="scale-75"
//   //   //             inFront={true}
//   //   //           /> */}
//   //   //         </div>
//   //   //       </section>

//   //   //       {/* {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>} */}
//   //   //       {/* {tagline && <h3 className="h3 pt-20px">{tagline}</h3>} */}
//   //   //       {/* {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />} */}
//   //   //       {/* {link && linkText && (
//   //   //         <a
//   //   //           href={link}
//   //   //           className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //   //         >
//   //   //           {linkText}
//   //   //         </a>
//   //   //       )} */}
//   //   //     </div>
//   //   //   </section>
//   //   // );
//   //   return (
//   //     <section
//   //       data-component="Article"
//   //       className="z-10 py-section relative page-grid"
//   //     >
//   //       {image && (
//   //         <div
//   //           className={classNames(
//   //             "aspect-w-4 aspect-h-3",
//   //             "lg:row-start-1 lg:row-span-1",
//   //             "col-start-2 col-span-12",
//   //             side === "right"
//   //               ? "lg:col-start-2 lg:col-span-6"
//   //               : "lg:col-start-8 lg:col-span-6"
//   //           )}
//   //         >
//   //           <GatsbyImage
//   //             style={{ position: "absolute" }}
//   //             className={classNames()}
//   //             imgStyle={{
//   //               objectFit: "cover",
//   //               objectPosition: "center",
//   //             }}
//   //             image={image}
//   //             alt={alt || title || tagline || "image"}
//   //           />
//   //         </div>
//   //       )}
//   //       <div
//   //         className={classNames(
//   //           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //           "self-center",
//   //           side === "right"
//   //             ? "lg:col-start-9 lg:col-span-5"
//   //             : "lg:col-start-2 lg:col-span-5"
//   //         )}
//   //       >
//   //         <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
//   //           {Articletitle}
//   //         </h2>
//   //         <div
//   //           className={classNames(
//   //             // "relative w-full max-width py-section"
//   //             "relative w-full max-width mt-15px"
//   //             // styleclass
//   //           )}
//   //         >
//   //           {heading && (
//   //             <RTF
//   //               content={heading}
//   //               className={classNames(rightClassName, "mb-40px")}
//   //               classNameOverrides={{ p: "p3" }}
//   //             />
//   //           )}
//   //           {statements && (
//   //             <div
//   //               className={classNames(
//   //                 rightClassName,
//   //                 "border-b border-dark-blue row-start-2 mb-30px"
//   //               )}
//   //             />
//   //           )}
//   //           {statements && (
//   //             <div className={classNames(rightClassName, "row-start-3 ")}>
//   //               {statements.map(({ id, title, heading }) => {
//   //                 const hasRTF = !!heading;
//   //                 return (
//   //                   <Fragment key={id}>
//   //                     <h3
//   //                       className={classNames(
//   //                         rightClassName,
//   //                         !listing || smallfont != "YES"
//   //                           ? hasRTF
//   //                             ? "p2"
//   //                             : "p3"
//   //                           : "",
//   //                         "pt-20px"
//   //                       )}
//   //                     >
//   //                       {title}
//   //                     </h3>
//   //                     {hasRTF && (
//   //                       <RTF
//   //                         content={heading}
//   //                         className={classNames(rightClassName)}
//   //                         classNameOverrides={{ p: "p3" }}
//   //                       />
//   //                     )}
//   //                   </Fragment>
//   //                 );
//   //               })}
//   //             </div>
//   //           )}
//   //           {listing && (
//   //             <div className="p-10px">
//   //               {listing.map((t, index) => {
//   //                 return <li className="mt-10px mb-10px">{t}</li>;
//   //               })}
//   //             </div>
//   //           )}
//   //           {afterlisting && (
//   //             <h3 className={classNames(rightClassName, "", "pt-20px")}>
//   //               {afterlisting}
//   //             </h3>
//   //           )}
//   //         </div>
//   //         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//   //         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //         {link && linkText && (
//   //           <a
//   //             href={link}
//   //             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //           >
//   //             {linkText}
//   //           </a>
//   //         )}
//   //       </div>
//   //     </section>
//   //   );
//   // } else if (articlewithContent !== "YES") {
//   //   return (
//   //     <section
//   //       data-component="Article"
//   //       className="z-10 py-section relative page-grid"
//   //     >
//   //       {image && (
//   //         <div
//   //           className={classNames(
//   //             "aspect-w-4 aspect-h-3",
//   //             "lg:row-start-1 lg:row-span-1",
//   //             "col-start-2 col-span-12",
//   //             side === "right"
//   //               ? "lg:col-start-2 lg:col-span-6"
//   //               : "lg:col-start-8 lg:col-span-6"
//   //           )}
//   //         >
//   //           <GatsbyImage
//   //             style={{ position: "absolute" }}
//   //             className={classNames()}
//   //             imgStyle={{
//   //               objectFit: "cover",
//   //               objectPosition: "center",
//   //             }}
//   //             image={image}
//   //             alt={alt || title || tagline || "image"}
//   //           />
//   //         </div>
//   //       )}
//   //       <div
//   //         className={classNames(
//   //           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //           "self-center",
//   //           side === "right"
//   //             ? "lg:col-start-9 lg:col-span-5"
//   //             : "lg:col-start-2 lg:col-span-5"
//   //         )}
//   //       >
//   //         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//   //         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //         {link && linkText && (
//   //           <a
//   //             href={link}
//   //             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //           >
//   //             {linkText}
//   //           </a>
//   //         )}
//   //       </div>
//   //     </section>
//   //   );
//   // } else {
//   //   // return (
//   //   //   <section
//   //   //     data-component="Article"
//   //   //     className="z-10 py-section relative page-grid"
//   //   //   >
//   //   //     {image && (
//   //   //       <div
//   //   //         className={classNames(
//   //   //           "aspect-w-4 aspect-h-3",
//   //   //           "lg:row-start-1 lg:row-span-1",
//   //   //           "col-start-2 col-span-12",
//   //   //           side === "right"
//   //   //             ? "lg:col-start-2 lg:col-span-6"
//   //   //             : "lg:col-start-8 lg:col-span-6"
//   //   //         )}
//   //   //       >
//   //   //         <GatsbyImage
//   //   //           style={{ position: "absolute" }}
//   //   //           className={classNames()}
//   //   //           imgStyle={{
//   //   //             objectFit: "cover",
//   //   //             objectPosition: "center",
//   //   //           }}
//   //   //           image={image}
//   //   //           alt={alt || title || tagline || "image"}
//   //   //         />
//   //   //       </div>
//   //   //     )}
//   //   //     <div
//   //   //       className={classNames(
//   //   //         "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //   //         "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //   //         "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //   //         "self-center",
//   //   //         side === "right"
//   //   //           ? "lg:col-start-9 lg:col-span-5"
//   //   //           : "lg:col-start-2 lg:col-span-5"
//   //   //       )}
//   //   //     >
//   //   //       {title && (
//   //   //         <h2 className="h4 pt-20px md:pt-48px lg:pt-0">
//   //   //           {path == "/en/" ? title : "Nosso propósito"}{" "}
//   //   //         </h2>
//   //   //       )}
//   //   //       {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //   //       {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //   //       {link && linkText && (
//   //   //         <a
//   //   //           href={link}
//   //   //           className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //   //         >
//   //   //           {linkText}
//   //   //         </a>
//   //   //       )}
//   //   //     </div>
//   //   //   </section>
//   //   // );
//   //   return (
//   //     <section
//   //       data-component="Article"
//   //       className="z-10 py-section relative page-grid"
//   //     >
//   //       {image && (
//   //         <div
//   //           className={classNames(
//   //             "aspect-w-4 aspect-h-3",
//   //             "lg:row-start-1 lg:row-span-1",
//   //             "col-start-2 col-span-12",
//   //             side === "right"
//   //               ? "lg:col-start-2 lg:col-span-6"
//   //               : "lg:col-start-8 lg:col-span-6"
//   //           )}
//   //         >
//   //           <GatsbyImage
//   //             style={{ position: "absolute" }}
//   //             className={classNames()}
//   //             imgStyle={{
//   //               objectFit: "cover",
//   //               objectPosition: "center",
//   //             }}
//   //             image={image}
//   //             alt={alt || title || tagline || "image"}
//   //           />
//   //         </div>
//   //       )}
//   //       <div
//   //         className={classNames(
//   //           "relative lg:row-start-1 lg:row-span-2 text-right-margin",
//   //           "transition-max-height ease-bond transition-duration-200 overflow-hidden",
//   //           "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
//   //           "self-center",
//   //           side === "right"
//   //             ? "lg:col-start-9 lg:col-span-5"
//   //             : "lg:col-start-2 lg:col-span-5"
//   //         )}
//   //       >
//   //         {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
//   //         {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
//   //         {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
//   //         {link && linkText && (
//   //           <a
//   //             href={link}
//   //             className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
//   //           >
//   //             {linkText}
//   //           </a>
//   //         )}
//   //       </div>
//   //     </section>
//   //   );
//   // }
// };

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable no-constant-condition */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { FC, Fragment } from "react";
import { RTF } from "../elements";
// import { ValuesCollection } from "./ValuesCollection";
import video1 from "../images/door-hope.mp4";
export interface ArticleInformation {
  id: string;
  image?: IGatsbyImageData;
  alt?: string;
  side?: "left" | "right";
  title?: string;
  tagline?: string;
  detailsRTF?: CleanedRTF;
  link?: string;
  linkText?: string;
  articlewithContent?: string;
  statements?: {
    id: string;
    title: string | undefined;
    heading: CleanedRTF | undefined;
  }[];
  heading?: CleanedRTF | undefined;
  Articletitle?: string;
  video?: string;
  listing?: string[];
  afterlisting?: string;
  smallfont?: string;
  readmoreLink?: string;
}

export const Article: FC<ArticleInformation> = ({
  image,
  alt,
  side,
  title,
  tagline,
  detailsRTF,
  link,
  linkText,
  articlewithContent,
  statements,
  heading,
  Articletitle,
  video,
  listing,
  afterlisting,
  smallfont,
  readmoreLink,
}) => {
  if (articlewithContent == "YES") {
    const rightClassName = classNames(
      "text-right-margin space-y-20px",
      "col-start-2 col-span-12 mt-20px",
      "lg:col-start-7 lg:col-span-7 lg:mt-0"
    );
    return (
      <section
        data-component="Article"
        className="z-10 py-section relative page-grid"
      >
        {image && !video && (
          <div
            className={classNames(
              "aspect-w-4 aspect-h-3",
              "lg:row-start-1 lg:row-span-1",
              "col-start-2 col-span-12 mb-30px",
              side === "right"
                ? "lg:col-start-2 lg:col-span-6"
                : "lg:col-start-8 lg:col-span-6"
            )}
          >
            {/* <video src="https://www.veed.io/view/34eb129f-0d65-44b2-b288-14a2a168e32b?source=compressor-sharing"  width=100/> */}
            <GatsbyImage
              style={{ position: "absolute" }}
              className={classNames()}
              imgStyle={{
                objectFit: "fill",
                objectPosition: "center",
                height: "auto",
              }}
              image={image}
              alt={alt || title || tagline || "image"}
            />
            {/* <video
              // autoPlay={true}
              // muted={true}
              // loop={true}
              controls
              src={video}
              className={classNames(
                "absolute h-full w-full top-0 left-0 right-0 bottom-0",
                1 == 1 ? "object-center" : "object-right md:object-left"
                // "object-cover"
              )}
            /> */}
          </div>
        )}
        {video && (
          <div
            className={classNames(
              "aspect-w-4 aspect-h-3",
              "lg:row-start-1 lg:row-span-1",
              "col-start-2 col-span-12 mb-20px",
              side === "right"
                ? "lg:col-start-2 lg:col-span-6"
                : "lg:col-start-8 lg:col-span-6"
            )}
          >
            {/* <video src="https://www.veed.io/view/34eb129f-0d65-44b2-b288-14a2a168e32b?source=compressor-sharing"  width=100/> */}
            {/* <GatsbyImage
              style={{ position: "absolute" }}
              className={classNames()}
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              image={image}
              alt={alt || title || tagline || "image"}
            /> */}
            <video
              // autoPlay={true}
              // muted={true}
              // loop={true}
              controls
              src={video1}
              className={classNames(
                "absolute h-full w-full top-0 left-0 right-0 bottom-0",
                1 == 1 ? "object-center" : "object-right md:object-left"
                // "object-cover"
              )}
            />
          </div>
        )}
        <div
          className={classNames(
            "relative lg:row-start-1 lg:row-span-2 text-right-margin",
            "transition-max-height ease-bond transition-duration-200 overflow-hidden",
            "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
            "self-center",
            side === "right"
              ? "lg:col-start-9 lg:col-span-5"
              : "lg:col-start-2 lg:col-span-5"
          )}
        >
          <section
            data-component="Values Collection"
            className={classNames(
              "full-width max-width relative z-10 bg-gradient-to-t via-light-blue"
              // className
            )}
          >
            <h2 className="col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 p1">
              {Articletitle}
            </h2>
            <div
              className={classNames(
                // "relative w-full max-width py-section"
                "relative w-full max-width mt-15px"
                // styleclass
              )}
            >
              {heading && (
                <RTF
                  content={heading}
                  className={classNames(rightClassName, "mb-40px")}
                  classNameOverrides={{ p: "p3" }}
                />
              )}
              {statements && (
                <div
                  className={classNames(
                    rightClassName,
                    "border-b border-dark-blue row-start-2 mb-30px"
                  )}
                />
              )}
              {statements && (
                <div className={classNames(rightClassName, "row-start-3 ")}>
                  {statements.map(({ id, title, heading }) => {
                    const hasRTF = !!heading;
                    return (
                      <Fragment key={id}>
                        <h3
                          className={classNames(
                            rightClassName,
                            // !listing ||
                            //   (smallfont != "YES" && listing) ||
                            //   smallfont
                            //   ? hasRTF
                            //     ? "p2"
                            //     : "p3"
                            //   : "",
                            "pt-20px"
                          )}
                        >
                          {title}
                        </h3>
                        {hasRTF && (
                          <RTF
                            content={heading}
                            className={classNames(rightClassName)}
                            classNameOverrides={{ p: "p3" }}
                          />
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              )}
              {listing && (
                <div className="p-10px">
                  {listing.map((t, index) => {
                    // eslint-disable-next-line react/jsx-key
                    return <li className="mt-10px mb-10px">{t}</li>;
                  })}
                </div>
              )}
              {afterlisting && (
                <h3 className={classNames(rightClassName, "", "pt-20px")}>
                  {afterlisting}
                </h3>
              )}
              {readmoreLink && (
                <p>
                  <a href={readmoreLink}>
                    <button className="block read-more">Read more</button>
                  </a>
                </p>
              )}
              {/* <Dots
                type="White"
                className={classNames(
                  "hidden lg:block col-start-5 col-span-2",
                  "row-start-2",
                  "opacity-75"
                )}
                translate="scale-75"
                inFront={true}
              /> */}
            </div>
          </section>

          {/* {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>} */}
          {/* {tagline && <h3 className="h3 pt-20px">{tagline}</h3>} */}
          {/* {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />} */}
          {/* {link && linkText && (
            <a
              href={link}
              className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
            >
              {linkText}
            </a>
          )} */}
        </div>
      </section>
    );
  } else {
    return (
      <section
        data-component="Article"
        className="z-10 py-section relative page-grid"
      >
        {image && (
          <div
            className={classNames(
              "aspect-w-4 aspect-h-3",
              "lg:row-start-1 lg:row-span-1",
              "col-start-2 col-span-12",
              side === "right"
                ? "lg:col-start-2 lg:col-span-6"
                : "lg:col-start-8 lg:col-span-6"
            )}
          >
            <GatsbyImage
              style={{ position: "absolute" }}
              className={classNames()}
              imgStyle={{
                objectFit: "cover",
                objectPosition: "center",
              }}
              image={image}
              alt={alt || title || tagline || "image"}
            />
          </div>
        )}
        <div
          className={classNames(
            "relative lg:row-start-1 lg:row-span-2 text-right-margin",
            "transition-max-height ease-bond transition-duration-200 overflow-hidden",
            "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
            "self-center",
            side === "right"
              ? "lg:col-start-9 lg:col-span-5"
              : "lg:col-start-2 lg:col-span-5"
          )}
        >
          {title && <h2 className="h4 pt-20px md:pt-48px lg:pt-0">{title}</h2>}
          {tagline && <h3 className="h3 pt-20px">{tagline}</h3>}
          {detailsRTF && <RTF content={detailsRTF} className="pt-20px p3" />}
          {link && linkText && (
            <a
              href={link}
              className="read-more absolute left-0 bottom-0 w-full h-60px flex justify-start items-end bg-gradient-to-t from-white-1 read-more-gradient"
            >
              {linkText}
            </a>
          )}
        </div>
      </section>
    );
  }
};
