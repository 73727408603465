import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { RTF } from "../elements";
import { CarouselContent } from "./Carousel";
import { I18Link } from "./Links";

export const CenterCarouselContent: React.FC<CarouselContent> = ({
  image,
  narrowImage,
  alt,
  title,
  tagline,
  messageRTF,
  link,
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  if (!image && !title) {
    return null;
  }

  const hasLink = link && link !== "/";

  const containerClassName = classNames(
    "w-full grid-gap grid grid-cols-12",
    "relative flex-grow-0 flex-shrink-0"
  );

  const inside = (
    <>
      {image && (
        <div
          className={classNames(
            narrowImage ? "aspect-w-3 aspect-h-4" : "aspect-w-4 aspect-w-3",
            "row-start-1 row-span-1 h-500px w-full",
            "col-start-1 col-span-12",
            "mb-20px lg:mb-0",
            narrowImage
              ? "lg:col-start-9 lg:col-span-4"
              : "lg:col-start-6 lg:col-span-7"
          )}
        >
          <GatsbyImage
            style={{ position: "absolute" }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            image={image}
            alt={alt || title || "Image"}
          />
        </div>
      )}
      <div
        className={classNames(
          "relative lg:h-auto lg:row-start-1 lg:row-span-1 text-right-margin",
          "col-start-1 col-span-12 md:col-start-3 md:col-span-8",
          narrowImage
            ? "lg:col-start-1 lg:col-span-8 self-start"
            : "lg:col-start-1 lg:col-span-5 self-center"
        )}
      >
        {tagline && (
          <h3 className="h5 pb-20px">
            {path == "/en/" ? tagline : "Estudo de caso"}
            {tagline}
          </h3>
        )}
        {title && <h2 className="h3 pb-20px">{title}</h2>}
        {messageRTF && (
          <RTF content={messageRTF} className="pb-48px p3 text-right-margin" />
        )}
        {hasLink && (
          <p className="read-more">
            {path == "/en/" ? "Discover more" : "Descubra mais"}
          </p>
        )}
      </div>
    </>
  );

  if (hasLink) {
    return (
      <I18Link className={containerClassName} to={link}>
        {inside}
      </I18Link>
    );
  }
  return <div className={containerClassName}>{inside}</div>;
};
