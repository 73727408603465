import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { CarouselContent } from "./Carousel";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
import { RTF } from "../elements";
import { I18Link } from "./Links";

export const WideCarouselContent: React.FC<CarouselContent> = ({
  index,
  image,
  alt,
  title,
  tagline,
  messageRTF,
  link,
  id,
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  if (!image && !title) {
    return null;
  }
  const containerClassName = classNames(
    // "wide-carousel-grid w-full m-27px lg:m-auto md:m-auto md:w-full lg:w-desktop-12-margin-cols",
    "wide-carousel-grid w-full m-27px lg:m-auto md:m-auto md:w-full",
    index != 0 && "m-10px ",
    // index == 4 && "lg:ml-desktop-margin",
    "relative flex-grow-0 flex-shrink-0"
  );

  const inside = (
    <>
      {image && (
        <div
          className={classNames(
            "aspect-w-4 aspect-h-3",
            "row-start-1 row-span-1 w-full",
            // "row-start-1 row-span-1 w-desktop-6-cols",
            "col-start-2 col-span-12",
            "lg:col-start-7 lg:col-span-7"
          )}
        >
          <GatsbyImage
            style={{ position: "absolute" }}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            image={image}
            alt={alt || title || "Image"}
          />
        </div>
      )}
      <div
        className={classNames(
          "lg:h-auto lg:row-start-1 lg:row-span-1 text-right-margin",
          "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
          "lg:col-start-2 lg:col-span-5",
          "py-40px",
          "self-center"
        )}
      >
        {tagline && <h3 className="h5">{tagline}</h3>}
        {title && <h2 className="h3 pt-48px">{title}</h2>}
        {messageRTF && (
          <RTF content={messageRTF} className="pt-20px p3 text-right-margin" />
        )}
        {link && (
          <p className="block read-more pt-48px">
            {path == "/en/" ? "Discover more" : "Descubra mais"}
          </p>
        )}
      </div>
    </>
  );

  return (
    <div className={containerClassName} id={`${id}-${index}`}>
      {link ? (
        <I18Link
          className={classNames(
            "lg:row-start-1 lg:row-span-1",
            "col-start-1 col-span-13",
            // "wide-carousel-grid w-mobile-12-margin-cols md:w-tablet-12-margin-cols lg:w-desktop-12-margin-cols",
            "wide-carousel-grid w-mobile-12-margin-cols md:w-tablet-12-margin-cols ",
            "relative flex-grow-0 flex-shrink-0"
          )}
          to={link}
        >
          {inside}
        </I18Link>
      ) : (
        <div
          className={classNames(
            "lg:row-start-1 lg:row-span-1",
            "col-start-1 col-span-13",
            "wide-carousel-grid w-mobile-12-margin-cols md:w-tablet-12-margin-cols lg:w-desktop-12-margin-cols",
            // "wide-carousel-grid w-mobile-12-margin-cols md:w-tablet-12-margin-cols ",
            "relative flex-grow-0 flex-shrink-0"
          )}
        >
          {inside}
        </div>
      )}
      <div
        className={classNames(
          // "w-full flex justify-between",
          "w-full flex justify-between md:w-tablet-4-cols",
          "lg:row-start-1 lg:row-span-1",
          "col-start-2 col-span-12 md:col-start-4 md:col-span-8",
          "lg:col-start-2 lg:col-span-5",
          "self-end",
          "z-50"
        )}
      >
        <button className="prev-button" id="prevbtn">
          <img src={leftIcon} alt="Left" />
        </button>
        <button className="next-button pr-mobile-margin lg:mr-0">
          <img src={rightIcon} alt="Right" />
        </button>
      </div>
    </div>
  );
};
