import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import React from "react";
import { SimpleRTF } from "../../elements";

export const TestimonalsContentBlock: React.FC<{
  title?: string;
  subtitle?: CleanedRTF;
  content?: CleanedRTF;
}> = ({ title, subtitle, content }) => {
  const rightColumn =
    "col-start-2 md:col-start-4 col-span-12 md:col-span-8 lg:col-start-9 lg:col-span-4";

  return (
    <section
      data-component="Testimonials Content Block"
      className={classNames(
        "relative w-full max-width z-10 bg-gradient-to-t from-white-1 via-white-1 to-light-blue"
      )}
    >
      <div className="relative w-full page-grid auto-rows-min max-width py-section-large text-dark-blue">
        <h2 className="h2 col-start-2 col-span-10 md:col-start-4 md:col-span-8 lg:col-start-2 lg:col-span-6 row-start-1 mb-40px lg:mb-0">
          {title}
        </h2>
        <div className={rightColumn}>
          {subtitle && <SimpleRTF content={subtitle} className="p1 mb-48px" />}
          {content && (
            <SimpleRTF
              content={content}
              className="p2"
              classNameOverrides={{
                p: "pt-48px border-t border-dark-blue not-last:pb-48px",
              }}
            />
          )}
        </div>
      </div>
    </section>
  );
};
