import classNames from "classnames";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { PropsWithChildren } from "react";
import { GraphCms_Position } from "../generated/graphql-types";
import { ProfileInformation } from "./SingleProfile";

export const Quote: React.FC<
  PropsWithChildren<{
    profile?: ProfileInformation;
    quote?: string;
    author?: string;
    authorJobTitle?: string;
    authorPosition?: GraphCms_Position;
    className?: string;
    withoutqote?: string;
  }>
> = ({
  profile,
  quote,
  author,
  authorJobTitle,
  authorPosition,
  className,
  children,
  withoutqote,
}) => {
  if (!quote) {
    return null;
  }

  return (
    <section
      data-component="Quote"
      className={classNames(
        "py-section relative page-grid max-width z-10",
        className
      )}
    >
      {profile ? (
        <>
          {profile.image && (
            <div
              className={classNames(
                "flex justify-center lg:self-center",
                authorPosition === "Right"
                  ? "col-start-5 col-span-6 lg:col-start-11 lg:col-span-2"
                  : "col-start-5 col-span-6 lg:col-start-3 lg:col-span-2"
              )}
            >
              <div className="w-full max-w-250px">
                <GatsbyImage
                  imgStyle={{
                    objectFit: "contain",
                  }}
                  image={profile.image}
                  alt={profile.name}
                />
              </div>
            </div>
          )}
          <div
            className={classNames(
              "self-center lg:mt-none mt-20px",
              authorPosition === "Right"
                ? "col-start-3 col-span-9 lg:col-start-3 lg:col-span-7"
                : "col-start-3 col-span-9 lg:col-start-6 lg:col-span-7"
            )}
          >
            {withoutqote && <p className="p2">{quote}</p>}
            {!withoutqote && <p className="p2">‟{quote}”</p>}
            {/* <p className="p2">‟{quote}”</p> */}
            <p className="h3 pt-20px">{profile.name}</p>
            <p className="p4">
              {profile.jobTitle}
              {profile.company ? `, ${profile.company}` : undefined}
            </p>
          </div>
        </>
      ) : author ? (
        <>
          <div
            className={classNames(
              authorPosition === "Right"
                ? "col-start-5 col-span-6 lg:col-start-3 lg:col-span-7"
                : "col-start-2 col-span-6 lg:col-start-6 lg:col-span-7 text-right"
            )}
          >
            <p className="p2">‟{quote}”</p>
            {author && <p className="h3 pt-20px">{author}</p>}
            {authorJobTitle && <p className="p4 pt-20px">{authorJobTitle} </p>}
          </div>
        </>
      ) : (
        <div
          className={classNames(
            "text-center self-center",
            "col-start-2 col-span-12",
            "lg:col-start-4 lg:col-span-8"
          )}
        >
          <p className="p2">‟{quote}”</p>
        </div>
      )}
      {children}
    </section>
  );
};
