import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import { I18Link } from "./Links";

interface Props {
  id: string;
  link?: string;
  title?: string;
  tagline?: string;
  messageRTF?: CleanedRTF;
  alt?: string;
  image?: IGatsbyImageData;
}

export const PageLink: React.FC<Props> = ({
  link,
  title,
  tagline,
  messageRTF,
  alt,
  image,
}) => {
  const inside = (
    <>
      {image && (
        <GatsbyImage
          className={classNames(
            "h-300px lg:h-500px",
            "row-start-1",
            "col-start-2 col-span-12"
          )}
          imgStyle={{
            objectFit: "cover",
            objectPosition: "center",
          }}
          image={image}
          alt={alt || title || "Image"}
        />
      )}
      <div
        className={classNames(
          "mt-30px xl:mt-60px",
          "col-start-3 col-span-10 row-start-2",
          "xl:col-start-3 xl:col-span-4 xl:row-start-2 xl:row-span-3 xl:pb-section"
        )}
      >
        {tagline && <h3 className="h5 pb-10px">{tagline}</h3>}
        <h2 className="p1">{title}</h2>
      </div>
      {messageRTF && (
        <RTF
          content={messageRTF}
          className={classNames(
            "mt-30px xl:mt-60px",
            "col-start-3 col-span-10 row-start-3",
            "xl:col-start-8 xl:col-span-5 xl:row-start-2",
            "text-right-margin h4"
          )}
        />
      )}
      {link && (
        <p
          className={classNames(
            "col-start-3 col-span-10 row-start-4",
            "xl:col-start-8 xl:col-span-5 xl:row-start-3",
            "text-right-margin p4 mt-20px read-more",
            "mb-20px xl:mb-48px"
          )}
        >
          Discover More
        </p>
      )}
    </>
  );
  return (
    <section
      data-component="Page Link"
      className="pt-section relative page-grid max-width grid-flow-row-dense z-10"
    >
      {link ? <I18Link to={link}>{inside}</I18Link> : inside}
      <div className="col-start-2 col-span-12 row-start-1 row-span-5 bg-white-1 -z-10" />
    </section>
  );
};
