import classNames from "classnames";
import React from "react";
import { SearchIcon } from "../icons";

export const CustomSearch: React.FC<{ className?: string }> = ({
  className,
}) => {
  return (
    <form method="get" action="/search" className={className}>
      <input
        name="query"
        type="text"
        className={classNames(
          "p-0 pb-10px bg-transparent border-white-2 w-full border-0 border-b",
          "focus:ring-white-2 focus:border-white-2",
          "text-white-2 medium-body-copy"
        )}
      />
      <button type="submit" className="top-20px right-0 absolute">
        <SearchIcon className="text-white-2 h-20px" />
      </button>
    </form>
  );
};
