import classNames from "classnames";
import React, { useEffect, useRef } from "react";
interface Props {
  name?: string;
  className?: string;
  title?: string;
  subtitle?: string;
}
export const AwardDes: React.FC<Props> = ({ className, title, subtitle }) => {
  const sectionRef = useRef<HTMLElement>(null);
  useEffect(() => {
    // const ref = sectionRef.current;
  }, []);
  return (
    <section
      data-component="LogoDes"
      className={classNames(
        "relative w-full page-grid max-width z-10 ",
        className ? className : "py-2.5"
      )}
      ref={sectionRef}
    >
      <h3 className="h7 col-start-2 col-span-12 text-center leading-tight pb-5">
        {title}
      </h3>
      <br />
      <br />
      <h5 className="col-start-2 col-span-12 text-center leading-tight">
        {subtitle}
      </h5>
    </section>
  );
};
