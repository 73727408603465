import classNames from "classnames";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { Dots, StatisticDetails } from ".";
import { RTF } from "../elements";
import { useFirstVisibleToUser } from "../hooks";
import { lookupColour } from "../utils";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";

export function useScale(ticks = 100): [React.RefObject<HTMLElement>, number] {
  const [sectionRef, isVisible] = useFirstVisibleToUser();
  const [scale, setScale] = useState(0);
  useEffect(() => {
    if (isVisible) {
      let handle: number | undefined;
      let tick = 0;

      const handler = () => {
        handle = undefined;
        tick = tick + 1;
        if (tick >= ticks) {
          setScale(1);
        } else {
          setScale(tick / ticks);
          handle = window.requestAnimationFrame(handler);
        }
      };
      handle = window.requestAnimationFrame(handler);
      return () => {
        if (handle) {
          window.cancelAnimationFrame(handle);
          handle = undefined;
        }
      };
    }
  }, [isVisible, ticks]);

  return [sectionRef, scale];
}

export const Infographic: React.FC<
  PropsWithChildren<{
    title?: string;
    isTitleVisible?: boolean;
    headlineRTF?: CleanedRTF;
    statistics?: StatisticDetails[];
    className?: string;
    backgroundColour?: string;
    dotsAnimation?: string;
    fromwhere?: string;
  }>
> = ({
  title,
  isTitleVisible,
  headlineRTF,
  statistics,
  className,
  children,
  backgroundColour,
  dotsAnimation,
  fromwhere,
}) => {
  const [sectionRef, scale] = useScale();

  const backgroundColourClassName = lookupColour(
    backgroundColour,
    "Background"
  );
  const textColourClassName = lookupColour(backgroundColour, "Text");
  const text2ColourClassName = lookupColour(backgroundColour, "Text2");

  if (!title && !headlineRTF && !statistics) {
    return null;
  }
  if (fromwhere == "pipware") {
    return (
      <section
        data-component="Infographic"
        ref={sectionRef}
        className={classNames(
          "relative w-full max-width",
          className,
          backgroundColourClassName,
          textColourClassName
        )}
      >
        <div className="relative py-section content-grid z-10">
          {title && isTitleVisible && headlineRTF && (
            <>
              <h2 className="p1 col-start-1 col-span-5 text-right-margin mb-75px">
                {title}
              </h2>
              <RTF
                content={headlineRTF}
                className="p3 col-start-6 col-span-7 text-right-margin mb-75px mt-10px"
              />
            </>
          )}
          {title && !headlineRTF && (
            <h2 className="p1 col-start-2 col-span-10 text-center mb-75px">
              {title}
            </h2>
          )}
          <div className="relative col-start-2 col-span-full content-only-grid">
            {dotsAnimation && (
              <div className="absolute w-full h-full py-40px">
                <Dots
                  type={dotsAnimation}
                  className={classNames(
                    (statistics?.length || 0) > 5
                      ? "w-4/10 left-3/10 top-4/10"
                      : "w-2/10 left-4/10 top-5/10"
                  )}
                  inFront={true}
                />
              </div>
            )}
            {children}
            {statistics?.map((statistic, index) => {
              const isOdd = index % 2 === 1;
              const { prefix, amount, precision, unit, name, message } =
                statistic;
              const realPrecision = precision || 1;
              return (
                <div
                  key={index}
                  className={classNames(
                    "my-40px lg:text-left text-center col-span-12 lg:col-span-3 ",
                    isOdd ? "" : "text-left"
                  )}
                >
                  {amount !== undefined && (
                    <h3 className="h1 tabular-nums infographichading font-bold">
                      {prefix}
                      {Math.round(amount * scale * realPrecision) /
                        realPrecision}
                      {unit}
                    </h3>
                  )}
                  {name && (
                    <h4
                      className={classNames(
                        "h4 lg:h3 md:h3 infographichading ",
                        // isOdd ? "xl:ml-desktop-1-cols" : "xl:mr-desktop-1-cols",
                        text2ColourClassName
                      )}
                    >
                      {name}
                    </h4>
                  )}
                  {message && (
                    <p
                      className={classNames(
                        "p3",
                        // isOdd ? "xl:ml-desktop-1-cols" : "xl:mr-desktop-1-cols",
                        text2ColourClassName
                      )}
                    >
                      {message}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  } else {
    return (
      <section
        data-component="Infographic"
        ref={sectionRef}
        className={classNames(
          "relative w-full max-width",
          className,
          backgroundColourClassName,
          textColourClassName
        )}
      >
        <div className="relative py-section content-grid z-10">
          {title && isTitleVisible && headlineRTF && (
            <>
              <h2 className="p1 col-start-1 col-span-5 text-right-margin mb-75px">
                {title}
              </h2>
              <RTF
                content={headlineRTF}
                className="p3 col-start-6 col-span-7 text-right-margin mb-75px mt-10px"
              />
            </>
          )}
          {title && !headlineRTF && (
            <h2 className="p1 col-start-2 col-span-10 text-center mb-75px">
              {title}
            </h2>
          )}
          <div className="relative col-start-1 col-span-full content-only-grid">
            {dotsAnimation && (
              <div className="absolute w-full h-full py-40px">
                <Dots
                  type={dotsAnimation}
                  className={classNames(
                    (statistics?.length || 0) > 5
                      ? "w-4/10 left-3/10 top-4/10"
                      : "w-2/10 left-4/10 top-5/10"
                  )}
                  inFront={true}
                />
              </div>
            )}
            {children}
            {statistics?.map((statistic, index) => {
              const isOdd = index % 2 === 1;
              const { prefix, amount, precision, unit, name, message } =
                statistic;
              const realPrecision = precision || 1;
              return (
                <div
                  key={index}
                  className={classNames(
                    "my-40px col-span-4",
                    isOdd ? "col-start-9 text-right" : "col-start-1 text-left"
                  )}
                >
                  {amount !== undefined && (
                    <h3 className="h1 tabular-nums infographichading font-bold">
                      {prefix}
                      {Math.round(amount * scale * realPrecision) /
                        realPrecision}
                      {unit}
                    </h3>
                  )}
                  {name && (
                    <h4
                      className={classNames(
                        "h4 lg:h3 md:h3 infographichading font-normal lg:font-bold",
                        isOdd ? "xl:ml-desktop-1-cols" : "xl:mr-desktop-1-cols",
                        text2ColourClassName
                      )}
                    >
                      {name}
                    </h4>
                  )}
                  {message && (
                    <p
                      className={classNames(
                        "p3",
                        // isOdd ? "xl:ml-desktop-1-cols" : "xl:mr-desktop-1-cols",
                        isOdd ? "" : "xl:mr-desktop-1-cols",
                        text2ColourClassName
                      )}
                    >
                      {message}
                    </p>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </section>
    );
  }
};
