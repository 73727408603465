import { getCleanedRTF } from "@bond-london/graphcms-rich-text";
import React from "react";
import { RTF } from "../elements";
import { HillsAndValleysCarousel } from "./HillsAndValleysCarousel";
import { StatementInformation } from "./Statement";

interface Props {
  id: string;
  title?: string;
  statements: StatementInformation[];
}

export const StatementCarousel: React.FC<Props> = ({ id, statements }) => {
  return (
    <HillsAndValleysCarousel
      id={id}
      contents={statements.map(({ id, title, message }) => {
        const realRTF = getCleanedRTF(message);
        return (
          <div
            key={id}
            className="py-40px px-20px bg-gradient-to-b from-light-blue"
          >
            {title && <h3 className="h3 text-center">{title}</h3>}
            {realRTF && <RTF content={realRTF} className="p3 text-center" />}
          </div>
        );
      })}
    />
  );
};
