/* eslint-disable no-self-assign */
/* eslint-disable no-empty */
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import { I18Link } from "./Links";

interface Props {
  image?: IGatsbyImageData;
  alt?: string;
  title: string;
  messageContent?: CleanedRTF;
  link?: string;
}

const CollectionContentInside: React.FC<{ props: Props }> = ({
  props: { image, alt, title, messageContent, link },
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const localevalue = "en";
  // if (path.toLowerCase().search("/en") == -1) {
  //   if (path.toLowerCase().search("/pt_br") == -1) {
  //   } else {
  //     localevalue = "pt_BR";
  //   }
  // } else {
  //   localevalue = "en";
  // }
  // if (localevalue == "en") {
  //   title = title;
  // } else {
  //   if (title == "Global services") {
  //     title = "Serviços globais";
  //   }
  //   if (title == "Consulting") {
  //     title = "Consultando";
  //   }
  // }
  return (
    <>
      {image && (
        <div className="aspect-w-16 aspect-h-9">
          <GatsbyImage
            style={{ position: "absolute" }}
            className={classNames(
              "row-start-1 row-span-1",
              "col-start-2 col-span-12",
              "lg:col-start-7 lg:col-span-7"
            )}
            imgStyle={{
              objectFit: "cover",
              objectPosition: "center",
            }}
            image={image}
            alt={alt || title}
          />
        </div>
      )}
      <h4 className="pt-20px h4">{title}</h4>
      {messageContent && (
        <RTF className="pt-20px regular-body-copy" content={messageContent} />
      )}
      {link && (
        <p className="block pt-40px read-more">
          {localevalue == "en" ? "Discover more" : "Descubra mais"}
        </p>
      )}
    </>
  );
};

export const CollectionContent: React.FC<Props> = (props) => {
  let { link } = props;
  if (link == "/social-values") {
    link = "/our-social-values";
  }
  if (link) {
    return (
      <I18Link to={link}>
        <CollectionContentInside props={props} />
      </I18Link>
    );
  }
  return <CollectionContentInside props={props} />;
};
