import classNames from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import { buildMenu } from ".";
import { BuiltMenu, Footer, Menu } from "../components";
import CookieConsent, { getCookieConsentValue } from "react-cookie-consent";
import { GraphCms_Colour, GraphCms_Page } from "../generated/graphql-types";
import { lookupColour } from "../utils";
import {
  SEO,
  SiteMetadata,
  SiteBuildMetadata,
} from "@bond-london/gatsby-graphcms-components";

declare global {
  interface Window {
    dataLayer?: unknown[];
  }
}

interface LayoutQuery {
  homePage: GraphCms_Page;
  siteBuildMetadata: SiteBuildMetadata;
  site: {
    siteMetadata: SiteMetadata & { cookieName: string };
  };
}

export const Layout: React.FC<
  PropsWithChildren<{
    pagePath: string;
    menu?: BuiltMenu;
    footerMenu?: BuiltMenu;
    backgroundColour?: GraphCms_Colour;
    keywords?: string;
    title: string;
    description?: string;
    noIndex?: boolean;
  }>
> = ({
  pagePath,
  menu,
  footerMenu,
  children,
  backgroundColour,
  keywords,
  title,
  description,
  noIndex,
}) => {
  const info = useStaticQuery<LayoutQuery>(graphql`
    query LayoutQuery {
      homePage: graphCmsPage(slug: { eq: "home" }) {
        menu {
          ...MenuFragment
        }
        footerMenu {
          ...MenuFragment
        }
      }
      site {
        siteMetadata {
          siteName
          siteUrl
          cookieName
          logo
          sameAs
        }
      }
      siteBuildMetadata {
        buildYear: buildTime(formatString: "YYYY")
        buildTime(formatString: "dddd, MMMM d YYYY, h:mm:ss A")
      }
    }
  `);

  const realMenu = menu || buildMenu(info.homePage.menu);
  // console.log(info);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const realFooterMenu = footerMenu || buildMenu(info.homePage.footerMenu)!;
  const siteBuildMetadata = info.siteBuildMetadata;
  const siteMetadata = info.site.siteMetadata;
  //const pageTitle = `${title} | ${siteMetadata.siteName}`;
  const pageTitle = `${title}`;
  const onAccept = useCallback(() => {
    window.gtag?.("consent", "update", { ad_storage: "granted" });
  }, []);

  useEffect(() => {
    const cookieValue = getCookieConsentValue(siteMetadata.cookieName);
    if (cookieValue) {
      onAccept();
    }
  }, [siteMetadata.cookieName, onAccept]);

  return (
    <>
      <CookieConsent
        cookieName={siteMetadata.cookieName}
        declineCookieValue="declined"
        containerClasses="bg-light-grey page-grid fixed w-full z-50 items-center py-20px gap-y-20px"
        disableStyles={true}
        disableButtonStyles={true}
        location={"top"}
        buttonWrapperClasses={classNames(
          "flex",
          "col-start-2 col-span-12 justify-around",
          "md:col-start-10 md:col-span-4 md:justify-end md:gap-tablet-gap lg:gap-desktop-gap"
        )}
        buttonClasses="bg-dark-blue text-white-2 px-10px h-30px"
        buttonText="Accept"
        enableDeclineButton={true}
        declineButtonText="Decline"
        declineButtonClasses="border border-dark-blue text-dark-blue px-10px h-30px"
        contentClasses="col-start-2 col-span-12 md:col-start-2 md:col-span-8"
        onAccept={onAccept}
        customContainerAttributes={{}}
        customContentAttributes={{}}
      >
        By clicking ‘Accept’, you agree to our use of cookies to measure the
        performance of our site and provide you with content and advertising
        tailored to your interests.
      </CookieConsent>
      <SEO
        pageTitle={pageTitle}
        siteBuildMetadata={siteBuildMetadata}
        siteMetadata={siteMetadata}
        pageMetadata={{ title, description, keywords, noIndex }}
        pagePath={pagePath}
        className={classNames(
          backgroundColour
            ? lookupColour(backgroundColour, "Background")
            : "bg-light-grey",
          process.env.GATSBY_DEBUG_TAILWIND && "debug-screens"
        )}
      />
      {realMenu?.entries && <Menu entries={realMenu.entries} />}
      {children}
      {realFooterMenu?.entries && (
        <Footer
          menu={realFooterMenu.entries}
          message={realFooterMenu?.message}
        />
      )}
    </>
  );
};
