import React, { PropsWithChildren } from "react";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";

export const SimpleTextBlock: React.FC<
  PropsWithChildren<{
    contentRTF?: CleanedRTF;
  }>
> = ({ contentRTF, children }) => {
  // window.addEventListener("load", function () {
  //   // Your code that depends on the window object
  // });
  // if (typeof window === "undefined" || !window.document) {
  //   return;
  // }
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  if (contentRTF) {
    return (
      <section
        data-component="Simple Text Block"
        className="py-section relative page-grid z-10"
      >
        <RTF
          content={contentRTF}
          className={classNames(
            "col-start-2 col-span-12",
            path.includes("pipware/security")
              ? " lg:col-start-3 lg:col-span-8"
              : "lg:col-start-7 lg:col-span-6"
          )}
        />
        {children}
      </section>
    );
  }
  return null;
};
