/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-empty */
import classNames from "classnames";
import React, { useCallback, useState } from "react";
import smallLogo from "../images/pip-small-logo.svg";
import menuIcon from "../images/Menu Button.svg";
import { CustomSearch, I18Link } from ".";
import { SvgInformation } from "@bond-london/gatsby-graphcms-components";

export interface MenuEntry {
  name: string;
  icon?: SvgInformation;
  link?: string;
  subMenu?: MenuEntry[];
  externalUrl?: string;
  value: string;
}

export interface BuiltMenu {
  entries?: MenuEntry[];
  message?: string;
}

interface SubProps {
  isExpanded: boolean;
  entries: MenuEntry[];
  className?: string;
  localevalues?: string;
  onClick?: () => void;
}

const SubMenuLevel: React.FC<SubProps> = ({
  isExpanded,
  entries,
  className,
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const localevalue = "en";
  // if (path.toLowerCase().search("/en") == -1) {
  //   if (path.toLowerCase().search("/pt_br") == -1) {
  //   } else {
  //     localevalue = "pt_BR";
  //   }
  // } else {
  //   localevalue = "en";
  // }
  return (
    <ul
      data-component="Menu"
      className={classNames(
        isExpanded
          ? "max-w-full max-h-massive p-30px lg:p-35px"
          : "max-w-0 max-h-0 p-0",
        "space-y-20px",
        // "lg:absolute lg:bottom-72px lg:space-y-35px",
        "lg:absolute lg:bottom-72px",
        "bg-dark-blue overflow-hidden flex flex-col text-white-1",
        "justify-between items-start",
        className
      )}
    >
      {entries.map(
        ({ name, link, externalUrl, value }, index) =>
          value.includes(localevalue) && (
            <li key={index}>
              {externalUrl && <a href={externalUrl}>{name}</a>}
              {link && <I18Link to={link}>{name}</I18Link>}
              {!link && !externalUrl && <p>{name}</p>}
            </li>
          )
      )}
    </ul>
  );
};

const MenuLevel: React.FC<SubProps> = ({
  isExpanded,
  entries,
  className,
  onClick,
}) => {
  const [expanded, setExpanded] = useState<string>();
  const toggleExpanded = useCallback(
    (name: string) =>
      setExpanded((e) => {
        if (name === e) {
          return undefined;
        }
        return name;
      }),
    []
  );
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const localevalue = "en";
  // if (path.toLowerCase().search("/en") == -1) {
  //   if (path.toLowerCase().search("/pt_br") == -1) {
  //   } else {
  //     localevalue = "pt_BR";
  //   }
  // } else {
  //   localevalue = "en";
  // }
  const valeus = {
    icon: undefined,
    link: "/wiredup-change-management-software",
    name: "WiredUp Software Suite",
    value: "en,pt_BR",
  };

  entries[1].subMenu[8] = valeus;

  // console.log(entries);
  return (
    <>
      <ul
        className={classNames(
          isExpanded
            ? "max-w-full max-h-massive"
            : "max-w-0 max-h-0 overflow-hidden",
          "lg:max-w-full lg:max-h-massive lg:overflow-visible",
          "relative flex text-white-1 ",
          "flex-col space-y-20px",
          "lg:flex-row lg:space-y-0 lg:justify-around lg:items-center",
          className
        )}
      >
        <li key="logo" className="lg:hidden ml-30px">
          <I18Link to="/" onClick={onClick}>
            <img src={smallLogo} alt="Logo" />
          </I18Link>
        </li>
        {entries.map(
          ({ name, link, externalUrl, subMenu, value }, index) =>
            value.includes(localevalue) && (
              <li key={index}>
                {externalUrl && (
                  <a className="mx-30px lg:mx-0" href={externalUrl}>
                    {name}
                  </a>
                )}
                {link && (
                  <I18Link
                    to={link}
                    className="mx-30px lg:mx-none"
                    onClick={link === "/" ? onClick : undefined}
                  >
                    {name}
                  </I18Link>
                )}
                {subMenu && (
                  <>
                    <button
                      onClick={() => toggleExpanded(name)}
                      className={classNames(
                        "w-full text-left px-30px lg:px-none",
                        expanded === name && "bg-dark-blue text-light-blue"
                      )}
                    >
                      {name}
                    </button>
                    <SubMenuLevel
                      entries={subMenu}
                      isExpanded={expanded === name}
                    />
                  </>
                )}
                {!link && !subMenu && !externalUrl && (
                  <p className="mx-30px lg:mx-none">{name}</p>
                )}
              </li>
            )
        )}
      </ul>
    </>
  );
};

interface Props {
  entries: MenuEntry[];
}

export const Menu: React.FC<Props> = ({ entries }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = useCallback(() => setIsExpanded((ie) => !ie), []);
  const closeMenu = useCallback(() => setIsExpanded(false), []);

  return (
    <nav
      className={classNames(
        "absolute",
        "right-0",
        "lg:fixed lg:left-0 lg:top-auto lg:right-auto lg:mr-auto lg:bottom-0 lg:h-72px flex flex-col lg:flex-row z-20",
        isExpanded && "w-full h-full bg-purple py-30px",
        "lg:w-full lg:bg-purple lg:h-auto lg:p-none",
        "z-100"
      )}
    >
      <button
        className={classNames(
          "absolute top-20px right-20px z-50 w-35px rounded-full lg:hidden"
        )}
        onClick={toggleExpanded}
      >
        <img src={menuIcon} alt="Menu" />
      </button>
      <MenuLevel
        isExpanded={isExpanded}
        entries={entries}
        className="w-full"
        onClick={closeMenu}
      />
      {isExpanded && (
        <CustomSearch className="relative lg:hidden pt-20px z-10 mx-30px" />
      )}
    </nav>
  );
};
