/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/no-unescaped-entities */
import { LottieInformation } from "@bond-london/gatsby-graphcms-components";
import classNames from "classnames";
import React from "react";

function calculatePosition(index: number, hasOffset: boolean) {
  let mobileClassName: string;
  let desktopClassName: string;
  switch (index % 3) {
    case 0:
      mobileClassName = "col-start-2";
      break;
    default:
      // mobileClassName = "col-start-8";
      mobileClassName = "col-start-2";
      break;
  }
  if (hasOffset) {
    // switch (index % 2) {
    //   case 0:
    //     desktopClassName = "lg:col-start-7";
    //     // desktopClassName = "";
    //     break;
    //   default:
    //     desktopClassName = "lg:col-start-3";
    //     // desktopClassName = "";
    //     break;
    // }
    switch (index % 3) {
      case 0:
        desktopClassName = "lg:col-start-3";
        break;
      case 1:
        desktopClassName = "lg:col-start-7";
        break;
      default:
        desktopClassName = "lg:col-start-11";
        break;
    }
  } else {
    switch (index % 3) {
      case 0:
        desktopClassName = "lg:col-start-3";
        break;
      case 1:
        desktopClassName = "lg:col-start-7";
        break;
      default:
        desktopClassName = "lg:col-start-11";
        break;
    }
  }
  return classNames(mobileClassName, desktopClassName);
}

interface Props {
  title?: string;
  offset?: boolean;
  contents: React.ReactElement[];
  animation?: LottieInformation;
  individualFirstLine?: boolean;
  fullFirstLine?: boolean;
}
export const PeopleCollection: React.FC<Props> = ({
  title,
  offset,
  contents,
  animation,
  individualFirstLine,
  fullFirstLine,
}) => {
  // const indexOffset = individualFirstLine ? 2 : fullFirstLine ? 0 : 1;
  console.log(animation);
  const indexOffset = 0;
  return (
    <section
      data-component="People Collection"
      className={classNames(
        fullFirstLine ? "pb-section" : "py-section",
        "relative max-width z-10"
      )}
    >
      {title && (
        <h2 className="h3 w-full text-center px-100px pb-section lg:hidden">
          {title}
        </h2>
      )}

      <div className="page-grid grid-flow-row-dense">
        {title && (
          <h2 className="h3 col-start-1 col-span-12 lg:col-start-2 lg:col-span-12 lg:row-start-1 hidden lg:block  lg:mb-30px">
            {title}
          </h2>
        )}
        {fullFirstLine ? undefined : offset ? (
          <div
            className={classNames(
              "row-start-1 col-span-6 lg:col-span-12",
              calculatePosition(0, true)
            )}
          />
        ) : (
          <div
            className={classNames(
              // "col-span-6 lg:col-span-3 row-span-2 row-start-1",
              // calculatePosition(0, false),
              ""
            )}
          />
        )}
        {/* {individualFirstLine && (
          <div
            data-component="Spacer"
            className={classNames(
              "row-start-1 row-span-2 lg:col-span-3 hidden lg:block",
              calculatePosition(2, false)
            )}
          />
        )} */}
        {contents.map((content, index) => (
          <div
            key={content.key}
            className={
              content.key != ""
                ? classNames(
                    "col-span-12 lg:col-span-3 row-span-2 ga-w80",
                    calculatePosition(
                      individualFirstLine && index === 0
                        ? 0
                        : index + indexOffset,
                      !!offset
                    )
                  )
                : ""
            }
          >
            {content}
          </div>
        ))}
        {/* <Dots
          animation={animation}
          className={classNames(
            "hidden lg:block",
            "col-start-1 col-span-5",
            offset ? "row-start-2" : "row-start-2"
          )}
        /> */}
      </div>
    </section>
  );
};
