/* eslint-disable no-empty */
import classNames from "classnames";
import React, { PropsWithChildren, useCallback, useState } from "react";
import { ExternalVideoPlayer, RTF } from "../elements";
import {
  ClassNameOverrides,
  CleanedRTF,
  GenericRichTextNode,
  getCleanedRTF,
  getRTFReferences,
} from "@bond-london/graphcms-rich-text";

export interface StatementInformation {
  id: string;
  title?: string;
  headline?: string;
  message?: GenericRichTextNode;
  cleanedMessage?: CleanedRTF;
  className?: string;
  rtfClassNameOverrides?: ClassNameOverrides;
  externalVideoUrl?: string;
  externalVideoTitle?: string;
  rtfClassName?: string;
  Frompage?: string;
}

export const Statement: React.FC<PropsWithChildren<StatementInformation>> = ({
  title,
  headline,
  message,
  cleanedMessage,
  className,
  rtfClassNameOverrides,
  children,
  externalVideoUrl,
  externalVideoTitle,
  rtfClassName,
  Frompage,
}) => {
  const [showExternalVideo, setShowExternalVideo] = useState(false);
  const openExternalVideo = useCallback(() => setShowExternalVideo(true), []);
  const closeExternalVideo = useCallback(() => setShowExternalVideo(false), []);
  let realRTF;
  // if (typeof window === "undefined" || !window.document) {
  //   return;
  // }
  // const currentUrl = window.location.pathname;
  if (cleanedMessage) {
    realRTF = cleanedMessage;
  } else if (message) {
    realRTF = getCleanedRTF(message);
  }

  if (Frompage == "caseStudy") {
  } else {
    if (!title && !headline && (!message || !cleanedMessage)) {
      return null;
    }
  }

  let leftClassName: string;
  let rightClassName: string;

  if (Frompage == "caseStudy") {
    leftClassName = "";
    rightClassName =
      "col-start-2 col-span-12 lg:col-start-2 lg:col-span-12 text-center mt-20px lg:mt-0 p1";
  } else {
    if (!title && !headline) {
      leftClassName = "";
      rightClassName =
        "col-start-2 col-span-12 lg:col-start-4 lg:col-span-8 text-center mt-20px lg:mt-0 p1";
    } else if (headline) {
      leftClassName =
        "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
      rightClassName =
        "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
    } else {
      leftClassName =
        "col-start-2 col-span-12 lg:col-start-2 lg:col-span-5 lg:row-span-2";
      rightClassName =
        "col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3";
    }
  }
  if (Frompage == "OUR-PEOPLE") {
    return (
      <section
        data-component="Statement"
        className={classNames(
          "py-section page-grid max-width z-0 bg-white-1 ",
          className
        )}
      >
        {title && (
          <span
            className={classNames(
              leftClassName,
              "lg:row-span-2 p1 text-purple lg:mb-0 lg:mt-0"
            )}
          >
            {title}
            <br />
            {headline && (
              <p
                className={classNames(
                  leftClassName,
                  "mt-20px lg:mt-0 text-right-margin  lg:text-20 text-dark-blue"
                )}
              >
                <b>{headline}</b>
              </p>
            )}
          </span>
        )}
        {/* {headline && (
          <p
            className={classNames(
              leftClassName,
              "mt-20px lg:mt-0 text-right-margin p3 lg:text-20"
            )}
          >
            <b>{headline}</b>
          </p>
        )} */}
        <div className="p3 space-y-20px col-start-2 col-span-12 lg:col-start-7 lg:col-span-7 mt-20px lg:mt-0 text-right-margin p3">
          <p>
            Start your journey today with a quick conversation. Our chat-based
            interview makes it easy to complete the first stage of the process.
            The best part,{" "}
            <b className="text-purple">
              everyone who interviews receives immediate and personalized
              feedback
            </b>{" "}
            to help you discover hidden strengths and potential growth areas.
          </p>
        </div>

        {/* {realRTF && (
          <RTF
            content={realRTF}
            className={classNames(rightClassName, rtfClassName)}
            classNameOverrides={rtfClassNameOverrides}
            references={getRTFReferences(message)}
          />
        )} */}
        {externalVideoTitle && externalVideoUrl && (
          <div
            className={classNames(
              rightClassName,
              "flex justify-center py-40px lg:justify-start"
            )}
          >
            <button
              className={classNames(
                showExternalVideo ? "hidden" : "block",
                "read-more"
              )}
              onClick={openExternalVideo}
            >
              {externalVideoTitle}
            </button>
            <ExternalVideoPlayer
              externalVideoUrl={externalVideoUrl}
              showExternalPlayer={showExternalVideo}
              onClose={closeExternalVideo}
            />
          </div>
        )}
        {children}
      </section>
    );
  } else {
    if (
      // currentUrl == "/en/careers/" &&
      title ==
      "An entrepreneurial spirit is at the heart of our firm as we look for new opportunities and ways to bring real value to our clients. "
    ) {
      return (
        <>
          <section
            data-component="Statement"
            className={classNames(
              "py-section page-grid max-width z-0",
              className
            )}
          >
            {title && (
              <h2 className={classNames(leftClassName, "lg:row-span-2 p1")}>
                {title}
              </h2>
            )}
            {headline && (
              <h3
                className={classNames(
                  leftClassName,
                  "mt-20px lg:mt-0 text-right-margin p3"
                )}
              >
                {headline}
              </h3>
            )}
            {realRTF && (
              <RTF
                content={realRTF}
                className={classNames(rightClassName, rtfClassName)}
                classNameOverrides={rtfClassNameOverrides}
                references={getRTFReferences(message)}
              />
            )}
            {externalVideoTitle && externalVideoUrl && (
              <div
                className={classNames(
                  rightClassName,
                  "flex justify-center py-40px lg:justify-start"
                )}
              >
                <button
                  className={classNames(
                    showExternalVideo ? "hidden" : "block",
                    "read-more"
                  )}
                  onClick={openExternalVideo}
                >
                  {externalVideoTitle}
                </button>
                <ExternalVideoPlayer
                  externalVideoUrl={externalVideoUrl}
                  showExternalPlayer={showExternalVideo}
                  onClose={closeExternalVideo}
                />
              </div>
            )}
            {children}
          </section>
          {/* <section className="py-section max-width z-0 pt-40px pb-20px">
            <center>
              <img
                src="https://media.graphassets.com/BG9LAw8Q7qhsqfVa8Vsh"
                className="max-w-full h-auto"
                alt="imgs"
              />
              <br></br>
              <Link
                to={
                  "https://info.pip.global/australia-and-new-zealand-graduate-program-2024"
                }
                target="_blank"
              >
                {" "}
                <button className="bg-purple ga-height41 ga-p8 text-white-1">
                  <b>Start your journey </b>
                </button>
              </Link>
            </center>
          </section> */}
        </>
      );
    } else {
      return (
        <section
          data-component="Statement"
          className={classNames(
            "py-section page-grid max-width z-0",
            className
          )}
        >
          {title && (
            <h2 className={classNames(leftClassName, "lg:row-span-2 p1")}>
              {title}
            </h2>
          )}
          {headline && (
            <h3
              className={classNames(
                leftClassName,
                "mt-20px lg:mt-0 text-right-margin p3"
              )}
            >
              {headline}
            </h3>
          )}
          {realRTF && (
            <RTF
              content={realRTF}
              className={classNames(rightClassName, rtfClassName)}
              classNameOverrides={rtfClassNameOverrides}
              references={getRTFReferences(message)}
            />
          )}
          {externalVideoTitle && externalVideoUrl && (
            <div
              className={classNames(
                rightClassName,
                "flex justify-center py-40px lg:justify-start"
              )}
            >
              <button
                className={classNames(
                  showExternalVideo ? "hidden" : "block",
                  "read-more"
                )}
                onClick={openExternalVideo}
              >
                {externalVideoTitle}
              </button>
              <ExternalVideoPlayer
                externalVideoUrl={externalVideoUrl}
                showExternalPlayer={showExternalVideo}
                onClose={closeExternalVideo}
              />
            </div>
          )}
          {children}
        </section>
      );
    }
  }
};
