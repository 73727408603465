import classNames from "classnames";
import React, { FC, useEffect, useRef } from "react";
import leftIcon from "../images/Left Button.svg";
import rightIcon from "../images/Right Button.svg";
import { createScroller } from "./Scroller";

interface Props {
  id?: string;
  contents: React.ReactElement[];
  title?: string;
}

export const CenterCarousel: FC<Props> = ({ title, contents }) => {
  const sectionRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const ref = sectionRef.current;
    if (ref) {
      const state = createScroller(ref);
      return state.destroy;
    }
  }, []);

  return (
    <section
      data-component="Center Carousel"
      className="relative w-full page-grid max-width py-section z-10"
      ref={sectionRef}
    >
      {title && (
        <h2
          className={classNames(
            "h2 col-start-2 col-span-12 text-center",
            "pb-section row-start-1"
          )}
        >
          {title}
        </h2>
      )}
      <button className="prev-button col-start-2 row-start-3 lg:row-start-2 lg:col-start-1 lg:col-span-1 h-full justify-self-start lg:justify-self-center">
        <img src={leftIcon} alt="Left" />
      </button>
      <div className="viewport col-start-2 col-span-12 relative row-start-2">
        <div className="scroll-container relative top-0 left-0 bottom-0 right-0 w-full flex grid-gap overflow-scroll hide-scrollbar">
          {contents}
        </div>
      </div>
      <button className="next-button col-start-13 row-start-3 lg:row-start-2 lg:col-start-14 lg:col-span-1 h-full justify-self-end lg:justify-self-center">
        <img src={rightIcon} alt="Right" />
      </button>
    </section>
  );
};
