const easeIn = "easeInQuart";
const easeOut = "easeOutQuart";

interface SlideState {
  slide: HTMLDivElement;
  left: number;
  right: number;
}
interface State {
  viewport: HTMLDivElement;
  scrollContainer: HTMLDivElement;
  previous: HTMLButtonElement[];
  next: HTMLButtonElement[];
  left: number;
  right: number;
  slides: SlideState[];
  destroy: () => void;
}

const baseDuration = 300;
const baseDelay = 100;
const distanceThreshold = 3;

interface X {
  found: boolean;
  index: number;
  lastVisibleIndex: number;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const log = (..._: unknown[]) =>
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  {};

function findNextSlide(state: State, moveNext: boolean) {
  const { scrollContainer, left, right, slides } = state;
  const { scrollLeft } = scrollContainer;
  const width = right - left;
  const containerLeft = scrollLeft;
  const containerRight = containerLeft + width;

  const reduce = (cb: (p: X, s: SlideState, i: number) => X, state: X) =>
    moveNext ? slides.reduceRight<X>(cb, state) : slides.reduce<X>(cb, state);

  const { found, index, lastVisibleIndex } = reduce(
    (x, slide, index) => {
      if (x.found) {
        return x;
      }

      const isVisible =
        slide.right - distanceThreshold > containerLeft &&
        slide.left + distanceThreshold < containerRight;
      const isFullyVisible =
        slide.left + distanceThreshold >= containerLeft &&
        slide.right - distanceThreshold <= containerRight;

      log({
        index,
        isVisible,
        isFullyVisible,
        distanceThreshold,
        containerLeft,
        containerRight,
      });

      if (isFullyVisible) {
        return {
          found: true,
          index: x.index === -1 ? index : x.index,
          lastVisibleIndex: -1,
        };
      } else if (isVisible && x.lastVisibleIndex === -1) {
        return { found: false, index, lastVisibleIndex: index };
      }
      return { ...x, index };
    },
    { found: false, index: -1, lastVisibleIndex: -1 }
  );
  log({ found, index, lastVisibleIndex });

  if (!found) {
    log("not found");
    return lastVisibleIndex;
  }
  return index;
}

function findEdgePosition(state: State, moveNext: boolean) {
  const nextIndex = findNextSlide(state, moveNext);
  const { slides } = state;

  log({ nextIndex, slides });

  const nextSlide = slides[nextIndex];

  // Move next aligns a slide fully right
  if (moveNext) {
    return { nextIndex, edgePosition: nextSlide.right };
  } else {
    return { nextIndex, edgePosition: nextSlide.left };
  }
}

function findSlidesInContainer(
  slides: SlideState[],
  containerLeft: number,
  containerRight: number,
  moveNext: boolean
) {
  const width = containerRight - containerLeft;

  function accumulateSlides(acc: SlideState[], slide: SlideState) {
    let realLeft: number, realRight: number;
    if (acc.length) {
      if (moveNext) {
        realLeft = acc[0].left;
        realRight = realLeft + width;
      } else {
        realRight = acc[0].right;
        realLeft = realRight - width;
      }
    } else {
      realLeft = containerLeft;
      realRight = containerRight;
    }

    const isVisible = slide.right >= realLeft && slide.left <= realRight;
    if (isVisible) {
      return acc.concat(slide);
    }
    return acc;
  }

  const empty: SlideState[] = [];
  const result = moveNext
    ? slides.reduce(accumulateSlides, empty)
    : slides.reduceRight(accumulateSlides, empty);

  return result;
}

function disableButtons(state: State, next: boolean, disabled: boolean) {
  const buttons = next ? state.next : state.previous;
  buttons.forEach((b) => (b.disabled = disabled));
}

function calculateNewViewPosition(
  slides: SlideState[],
  nextIndex: number,
  moveNext: boolean,
  edgePosition: number,
  width: number
) {
  if (!moveNext && nextIndex === 0) {
    return { nextLeft: slides[0].left, nextRight: slides[0].left + width };
  }

  if (moveNext && nextIndex === slides.length - 1) {
    return {
      nextRight: slides[nextIndex].right,
      nextLeft: slides[nextIndex].right - width,
    };
  }

  if (moveNext) {
    const maxSlide = slides[slides.length - 1];
    const slideWidth = maxSlide.right - maxSlide.left;
    const maxPosition = maxSlide.right;
    const candidateRight = edgePosition;
    const slideRight =
      candidateRight > maxPosition ? maxPosition : candidateRight;
    const slideLeft = slideRight - slideWidth;
    const nextRight =
      slideLeft + width > maxPosition ? maxPosition : slideLeft + width;
    const nextLeft = nextRight - width;

    log({
      slides,
      maxSlide,
      maxPosition,
      candidateRight,
      nextRight,
      nextLeft,
      width,
      edgePosition,
    });
    return { nextLeft, nextRight };
  } else {
    const minSlide = slides[0];
    const slideWidth = minSlide.right - minSlide.left;
    const minPosition = minSlide.left;
    const candidateLeft = edgePosition;
    const slideLeft = candidateLeft < minPosition ? minPosition : candidateLeft;
    const slideRight = slideLeft + slideWidth;
    const nextLeft =
      slideRight - width < minPosition ? minPosition : slideRight - width;
    const nextRight = nextLeft + width;
    return { nextLeft, nextRight };
  }
}

function handleButtonClick(state: State, moveNext: boolean) {
  disableButtons(state, moveNext, true);
  const { scrollContainer, left, right, slides } = state;
  const { scrollLeft } = scrollContainer;
  const width = right - left;
  const containerLeft = scrollLeft;
  const containerRight = containerLeft + width;
  const { nextIndex, edgePosition } = findEdgePosition(state, moveNext);

  const { nextLeft, nextRight } = calculateNewViewPosition(
    slides,
    nextIndex,
    moveNext,
    edgePosition,
    width
  );
  //  moveNext
  //   ? { nextLeft: edgePosition, nextRight: edgePosition + width }
  //   : { nextLeft: edgePosition - width, nextRight: edgePosition };

  log({ nextLeft, nextRight, edgePosition, width, moveNext });

  const visibleSlides = findSlidesInContainer(
    slides,
    containerLeft,
    containerRight,
    moveNext
  );
  log(
    "visible slides",
    visibleSlides.map((vs) => vs.slide.id)
  );

  const nextSlides = findSlidesInContainer(
    slides,
    nextLeft,
    nextRight,
    moveNext
  );
  log(
    "next slides",
    nextSlides.map((ns) => ns.slide.id)
  );
  const newScrollLeft = nextLeft;
  // moveNext
  //   ? nextSlides[0].left
  //   : nextSlides[nextSlides.length - 1].left;

  if (Math.abs(newScrollLeft - containerLeft) < 3) {
    log("not enough to move");
    disableButtons(state, moveNext, false);
    return;
  }

  const newTranslateX = moveNext ? "translateX(200vw)" : "translateX(-100vw)";
  const oldTranslateX = moveNext ? "translateX(-100vw)" : "translateX(100vw)";

  const lastOut = visibleSlides[visibleSlides.length - 1];
  const lastSlideOutTransitionedHandler = () => {
    log("last slide transitioned out");
    lastOut.slide.removeEventListener(
      "transitionend",
      lastSlideOutTransitionedHandler,
      true
    );

    nextSlides.forEach((slide) => {
      slide.slide.style.transitionTimingFunction = "";
      slide.slide.style.transitionDelay = "";
      slide.slide.style.transitionDuration = "";
      slide.slide.style.transform = newTranslateX;
      // log(`position ${slide.slide.id}`);
    });

    scrollContainer.scrollLeft = newScrollLeft;
    log(`scroll to ${newScrollLeft}`);
    setTimeout(() => {
      const lastIn = nextSlides[nextSlides.length - 1];
      const lastSlideInTransitionedHandler = () => {
        log("last slide transitioned in");
        lastIn.slide.removeEventListener(
          "transitionend",
          lastSlideInTransitionedHandler,
          true
        );
        slides.forEach((slide) => {
          slide.slide.style.transitionTimingFunction = "";
          slide.slide.style.opacity = "";
          slide.slide.style.transform = "";
          slide.slide.style.transitionDelay = "";
        });
        disableButtons(state, moveNext, false);
      };

      lastIn.slide.addEventListener(
        "transitionend",
        lastSlideInTransitionedHandler,
        true
      );
      nextSlides.forEach((nextSlide, index) => {
        nextSlide.slide.style.transitionTimingFunction = easeIn;
        nextSlide.slide.style.transitionDelay = `${index * baseDelay}ms`;
        nextSlide.slide.style.transitionDuration = `${baseDuration}ms`;
        nextSlide.slide.style.opacity = "1";
        nextSlide.slide.style.transform = "translateX(0)";
      }, 40);
    });
  };

  lastOut.slide.addEventListener(
    "transitionend",
    lastSlideOutTransitionedHandler,
    true
  );

  visibleSlides.forEach((slide, index) => {
    const slideElement = slide.slide;
    slideElement.style.transitionTimingFunction = easeOut;

    if (index) {
      slideElement.style.transitionDelay = `${index * baseDelay}ms`;
    } else {
      slideElement.style.transitionDelay = "";
    }
    slideElement.style.opacity = "0";
    slideElement.style.transform = oldTranslateX;
  });

  nextSlides.forEach((slide) => {
    const slideElement = slide.slide;
    if (!slideElement.style.transform) {
      slideElement.style.opacity = "0";
      slideElement.style.transform = newTranslateX;
    }
  });
}

function handleClick(ev: UIEvent, state: State, moveNext: boolean) {
  handleButtonClick(state, moveNext);
}

function configureSizing(state: State, rect: DOMRect) {
  const { slides } = state;
  const { left: viewportLeft, right: viewportRight } = rect;
  slides.forEach((slide) => {
    const slideLeft = slide.slide.offsetLeft;
    const slideRight = slideLeft + slide.slide.offsetWidth;
    slide.left = slideLeft - viewportLeft;
    slide.right = slideRight - viewportLeft;
  });

  state.left = viewportLeft;
  state.right = viewportRight;
}

export function createScroller(parent: HTMLElement): State {
  const previous: HTMLButtonElement[] = Array.from(
    parent.querySelectorAll("button.prev-button")
  );
  const viewport = parent.querySelector("div.viewport") as HTMLDivElement;
  const scrollContainer = viewport.querySelector(
    "div.scroll-container"
  ) as HTMLDivElement;
  const next: HTMLButtonElement[] = Array.from(
    parent.querySelectorAll("button.next-button")
  );
  const slides: SlideState[] = [];

  for (let i = 0; i < scrollContainer.children.length; i++) {
    const slide = scrollContainer.children[i] as HTMLDivElement;
    slides.push({
      slide,
      left: 0,
      right: 0,
    });
    slide.style.transitionProperty = "opacity, transform";
    slide.style.transitionDuration = `${baseDuration}ms`;
  }

  // eslint-disable-next-line prefer-const
  let state: State;

  const resizeObserver = new ResizeObserver((entries) => {
    for (const entry of entries) {
      // log("resize", entry.contentRect);
      configureSizing(state, entry.contentRect);
    }
  });
  resizeObserver.observe(scrollContainer);

  const nextClick = (ev: Event) => handleClick(ev as UIEvent, state, true);
  const previousClick = (ev: Event) => handleClick(ev as UIEvent, state, false);

  next.forEach((b) => b.addEventListener("click", nextClick, true));
  previous.forEach((b) => b.addEventListener("click", previousClick, true));

  const destroy = () => {
    resizeObserver.disconnect();
    next.forEach((b) => b.removeEventListener("click", nextClick, true));
    previous.forEach((b) => b.removeEventListener("click", nextClick, true));
  };

  state = {
    viewport,
    scrollContainer,
    previous,
    next,
    left: 0,
    right: 0,
    slides,
    destroy,
  };

  return state;
}
