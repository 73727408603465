import classNames from "classnames";
import React, { Fragment } from "react";
import { Dots } from ".";
import { RTF } from "../elements";
import { CleanedRTF } from "@bond-london/graphcms-rich-text";

export const FeaturesCollection: React.FC<{
  className?: string;
  title: string;
  heading: CleanedRTF | undefined;
  statements: {
    id: string;
    title: string | undefined;
    heading: CleanedRTF | undefined;
  }[];
  subText?: string;
}> = ({ className, title, heading, statements, subText }) => {
  const rightColumn =
    "col-start-2 md:col-start-4 col-span-12 md:col-span-8 lg:col-start-8 lg:col-span-6";
  return (
    <section
      data-component="Features Collection"
      className={classNames(
        "w-full max-width relative z-10 bg-gradient-to-t from-white-1 via-white-1 to-light-blue",
        className
      )}
    >
      <div className="relative w-full page-grid auto-rows-min max-width py-section-large">
        <h2 className="col-start-2 col-span-8 md:col-start-4 lg:col-start-2 lg:col-span-5 h3 row-start-1 mb-20px lg:mb-0">
          {title}
        </h2>
        {heading && (
          <RTF
            content={heading}
            className={classNames(
              rightColumn,
              "mb-50px md:mb-75px row-start-2lg:row-start-1"
            )}
            classNameOverrides={{ p: "p3" }}
          />
        )}
        <Dots
          type="White"
          className={classNames(
            "hidden lg:block col-start-6 col-span-2",
            "row-start-2 mt-15px",
            "opacity-75"
          )}
          translate="scale-75"
          inFront={true}
        />
        <div className={classNames(rightColumn)}>
          <div
            className={classNames(
              "border-b border-white",
              "mb-50px md:mb-75px",
              "row-start-2"
            )}
          />
          {statements.map(({ id, title, heading }) => (
            <Fragment key={id}>
              <h3 className={classNames("p2 mb-20px")}>{title}</h3>
              {heading && (
                <RTF
                  content={heading}
                  classNameOverrides={{ p: "p3 mb-50px" }}
                />
              )}
            </Fragment>
          ))}
        </div>
        <p className={classNames(rightColumn, "italic p3 lg:row-start-3")}>
          {subText}
        </p>
      </div>
    </section>
  );
};
