/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react/jsx-no-undef */
import {
  getExtractedSvg,
  getImage,
  getLottie,
  getVideo,
} from "@bond-london/gatsby-graphcms-components";
import { graphql, PageProps } from "gatsby";
import React from "react";
import {
  Blocks,
  BuiltMenu,
  getLink,
  Hero,
  Links,
  MenuEntry,
} from "../components";
import { Award } from "../components/award";
import { AwardDes } from "../components/awardescription";
import {
  GraphCms_Menu,
  GraphCms_MenuEntry,
  Query,
} from "../generated/graphql-types";
import { Layout } from "./Layout";

export function buildMenu(
  menu: GraphCms_Menu | undefined
): BuiltMenu | undefined {
  // console.log("******START Build MENU******");
  // console.log(menu?.menuEntries.map(buildMenuEntry));

  // console.log("******END BUILS MENU******");
  if (menu) {
    return {
      entries: menu.menuEntries.map(buildMenuEntry),
      message: menu.message,
    };
  }
}

export function buildMenuEntry(entry: GraphCms_MenuEntry): MenuEntry {
  const result: MenuEntry = {
    name: entry.name,
    icon: getExtractedSvg(entry.icon),
    value: entry.showingLocales.toString(),
  };
  if (entry.externalUrl) {
    result.externalUrl = entry.externalUrl;
  } else if (entry.pageLink) {
    result.link = getLink(entry.pageLink);
  } else if (entry.subMenuEntries) {
    result.subMenu = entry.subMenuEntries.map(buildMenuEntry);
  }
  return result;
}

export const GenericPage: React.FC<
  PageProps<Pick<Query, "graphCmsPage" | "allGraphCmsAward">>
> = ({
  path,
  data: {
    graphCmsPage: page,
    allGraphCmsAward: { nodes },
  },
}) => {
  if (!page) return null;

  return (
    <Layout
      pagePath={path}
      backgroundColour={page.backgroundColour}
      menu={buildMenu(page.menu)}
      footerMenu={buildMenu(page.footerMenu)}
      keywords={page.keywords}
      title={page.title}
      description={page.description}
    >
      <Hero
        title={page.title}
        image={getImage(page.heroImage)}
        video={getVideo(page.heroVideo)}
        animation={getLottie(page.heroAnimation)}
        short={page.shortHero}
        headings={page.heroMessage}
        colour={page.heroColour}
        showSearch={true}
        path={path}
        slug={page.slug}
        banners={path == "/en/" || path == "/pt_BR/" ? page.homeBanners : ""}
      />
      {/* {path && path == "/en/" && <GA_slider id={""} />}
      {path != "/en/" && (
        <Hero
          title={page.title}
          image={getImage(page.heroImage)}
          video={getVideo(page.heroVideo)}
          animation={getLottie(page.heroAnimation)}
          short={page.shortHero}
          headings={page.heroMessage}
          colour={page.heroColour}
          showSearch={true}
          path={path}
        />
      )} */}
      <Blocks blocks={page.blocks} />
      {page.title == "About us" && (
        <>
          <AwardDes
            title="Awards and recognition "
            subtitle="Partners in Performance is a great place to work, grow and to make a lasting impact around the world. We’re proud of the recognition we’ve received. Here are some highlights of our recent accomplishments."
          />
          <Award name="" nodes={nodes} />
        </>
      )}
      <Links links={page.bottomLinks} />
    </Layout>
  );
};

export const MenuEntryFragment = graphql`
  fragment MenuEntryFragment on GraphCMS_MenuEntry {
    id
    name
    icon {
      ...SvgFragment
    }
    pageLink {
      ...PageLinkFullFragment
    }
    externalUrl
    showingLocales
    assetToDownload {
      ...VideoFragment
    }
  }
`;

export const MenuFragment = graphql`
  fragment MenuFragment on GraphCMS_Menu {
    id
    name
    message
    menuEntries {
      ...MenuEntryFragment
      subMenuEntries {
        ...MenuEntryFragment
      }
    }
  }
`;

export const GenericPageLinkFragment = graphql`
  fragment GenericPageLinkFragment on GraphCMS_Page {
    __typename
    id
    slug
    heroImage {
      ...ImageFragment
    }
    title
    homeBanners {
      ctaLabel
      ctaActive
      ctaLink
      ctaPosition
      backgroundType
      language
      layoutMediaType
      layoutImage {
        linkUrl
        url
      }
      backgroundMedia {
        url
      }
      format
      title
      subtitle
    }
  }
`;

export const GenericPageFragment = graphql`
  fragment GenericPageFragment on GraphCMS_Page {
    ...GenericPageLinkFragment
    keywords
    description
    menu {
      ...MenuFragment
    }
    footerMenu {
      ...MenuFragment
    }
    heroVideo {
      ...VideoFragment
    }
    heroAnimation {
      ...LottieFragment
    }
    heroColour
    shortHero
    heroMessage
    backgroundColour
    blocks {
      __typename
      ... on GraphCMS_PeopleCollection {
        ...PeopleCollectionFragment
      }
      ... on GraphCMS_PageLink {
        ...PageLinkFullFragment
      }
      ... on GraphCMS_StatementCollection {
        ...StatementCollectionFragment
      }
      ... on GraphCMS_Quote {
        ...QuoteFragment
      }
      ... on GraphCMS_Article {
        ...ArticleFragment
      }
      ... on GraphCMS_ArticleCollection {
        ...ArticleCollectionFragment
      }
      ... on GraphCMS_Infographic {
        ...InfographicFragment
      }
      ... on GraphCMS_Statement {
        ...StatementFragment
      }
      ... on GraphCMS_PageLinkCollection {
        ...PageLinkCollectionFragment
      }
      ... on GraphCMS_RichTextBlock {
        ...RichTextBlockFragment
      }
      ... on GraphCMS_Form {
        ...FormFragment
      }
      ... on GraphCMS_PositionedAsset {
        ...PositionedAssetFragment
      }
    }
    bottomLinks {
      ...MenuEntryFragment
    }
  }
`;

export const MainHomePageFragment = graphql`
  fragment MainHomePageFragment on GraphCMS_Page {
    id
  }
`;
