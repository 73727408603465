import classNames from "classnames";
import React from "react";

interface Props {
  id: string;
  contents: React.ReactElement[];
  title?: string;
}

export const Collection: React.FC<Props> = ({ contents, title }) => {
  return (
    <section
      data-component="Collection"
      className="relative max-width py-section z-10 "
    >
      <div className="content-grid grid-flow-row-dense">
        {title && (
          <h2
            className={classNames(
              "h2 col-start-1 col-span-full text-center",
              "pb-section row-start-1"
            )}
          >
            {title}
          </h2>
        )}
        {contents.map((content, index) => {
          return (
            <div
              className={classNames(
                "col-start-1 col-span-12 md:col-start-auto md:col-span-6",
                index > 0 && "mt-60px lg:mt-0"
              )}
              key={content.key}
            >
              {content}
            </div>
          );
        })}
      </div>
    </section>
  );
};
