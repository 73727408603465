import React from "react";
import { RealRTF, RealRTFProps } from "@bond-london/graphcms-rich-text";
import classNames from "classnames";
import {
  ImageAssetRenderer,
  ImageAssetRendererProps,
} from "./ImageAssetRenderer";

const projectClassNameOverrides = {
  ul: "list-disc list-outside space-y-20px pl-revert",
  ol: "list-decimal list-outside space-y-20px pl-revert",
  h1: "h2",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  a: "underline",
};

export const RTF: React.FC<RealRTFProps> = ({
  className,
  references,
  renderers,
  ...props
}) => {
  return (
    <RealRTF
      {...props}
      className={classNames("p3 space-y-20px", className)}
      projectClassNameOverrides={projectClassNameOverrides}
      references={references}
      projectRenderers={{
        ...renderers,
        embed_asset: {
          image: (props) => (
            <ImageAssetRenderer
              {...(props as unknown as ImageAssetRendererProps)}
            />
          ),
        },
      }}
    />
  );
};

export const SimpleRTF: React.FC<RealRTFProps> = (props) => (
  <RealRTF {...props} projectClassNameOverrides={projectClassNameOverrides} />
);
