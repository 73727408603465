import { getImage } from "@bond-london/gatsby-graphcms-components";
import { graphql } from "gatsby";
import { getLink, SingleStory } from "./components";
import { ProfileInformation } from "./components/SingleProfile";
import {
  GraphCms_Person,
  GraphCms_SectorPageSuccesses,
  GraphCms_Story,
  GraphCms_Treatment,
} from "./generated/graphql-types";

export type ColourLookupType = "Background" | "Text" | "Text2";

const colourLookupArray: { [key: string]: string[] } = {
  White1: ["bg-white-1", "text-white-1", "text-dark-blue"],
  White2: ["bg-white-2", "text-white-2", "text-dark-blue"],
  Grey: ["bg-grey", "text-grey", "text-dark-blue"],
  LightGrey: ["bg-light-grey", "text-light-grey", "text-dark-blue"],
  DarkBlue: ["bg-dark-blue", "text-white-1", "text-light-blue"],
  MediumBlue: ["bg-medium-blue", "text-medium-blue", "text-dark-blue"],
  LightBlue: ["bg-light-blue", "text-dark-blue", "text-dark-blue"],
  Purple: ["bg-purple", "text-purple", "text-white-1"],
  LightPurple: ["bg-light-purple", "text-light-purple", "text-dark-blue"],
  GradientLightToDarkViaDark: [
    "bg-gradient-to-r from-medium-blue via-dark-blue to-dark-blue",
    "text-white-1",
    "text-light-blue",
  ],
};

export function lookupColour(
  colour: string | undefined,
  colourLookupType: ColourLookupType
): string | undefined {
  if (colour) {
    const result = colourLookupArray[colour];
    if (result) {
      switch (colourLookupType) {
        case "Background":
          return result[0];
        case "Text":
          return result[1];
        case "Text2":
          return result[2];
      }
    }
  }
}

export function numberOfDecimalPlaces(scale: number, amount: number): number {
  if (scale % 1 !== 0) {
    const decimalPlaces: number = scale.toString().split(".")[1]?.length | 2;
    return decimalPlaces;
  }
  if ((amount.toString().split("e")[1]?.length | 0) > 1) {
    return 6;
  } else {
    return amount.toString().split(".")[1]?.length | 0;
  }
}

export function getProfileInformation(
  node: GraphCms_Person | undefined
): ProfileInformation | undefined {
  if (node) {
    return {
      sector: node.coreSectors[0],
      country: node.country,
      image: getImage(node.image),
      jobTitle: node.jobTitle,
      company: node.company,
      linkedin: node.linkedIn,
      name: node.name,
      quote: node.quote,
      link: getLink(node),
    };
  }
}

export function getSingleStory(node: GraphCms_Story): SingleStory {
  return { title: node.title, link: `/story/${node.slug}` };
}

export function getSuccess(node: GraphCms_SectorPageSuccesses): SingleStory {
  const typename = node.__typename;
  switch (node.__typename) {
    case "GraphCMS_CaseStudy":
      return {
        title: node.pageLink?.optionalTitle || node.client || "Title",
        link: getLink(node.pageLink, node.slug, typename),
      };
    case "GraphCMS_Story":
      return {
        title: node.pageLink?.optionalTitle || node.title,
        link: getLink(node.pageLink, node.slug, typename),
      };

    case "GraphCMS_Insight":
      return {
        title: node.pageLink?.optionalTitle || node.title,
        link: getLink(node.pageLink, node.slug, typename),
      };
  }
  throw new Error(`No success mapping`);
}

export const imageFragment = graphql`
  fragment ImageFragment on GraphCMS_Asset {
    id
    alternateText
    remoteId
    mimeType
    url
    localFile {
      childImageSharp {
        gatsbyImageData
      }
    }
    linkUrl
  }
`;

export const videoFragment = graphql`
  fragment VideoFragment on GraphCMS_Asset {
    id
    alternateText
    localFile {
      publicURL
    }
  }
`;

export const lottieFragment = graphql`
  fragment LottieFragment on GraphCMS_Asset {
    id
    alternateText
    localFile {
      publicURL
      lottie {
        encoded
      }
    }
  }
`;

export const svgFragment = graphql`
  fragment SvgFragment on GraphCMS_Asset {
    id
    alternateText
    localFile {
      svg {
        encoded
      }
    }
  }
`;

export const profileFragment = graphql`
  fragment ProfileFragment on GraphCMS_Person {
    __typename
    id
    slug
    coreSectors
    country
    image {
      ...ImageFragment
    }
    jobTitle
    company
    linkedIn
    name
    quote
  }
`;

export const storyFragment = graphql`
  fragment StoryFragment on GraphCMS_Story {
    id
    title
    slug
  }
`;

export function getTreatmentClassName(
  treatment?: GraphCms_Treatment
): string | undefined {
  switch (treatment) {
    case "Darken":
      return "filter brightness-50 saturate-150";
  }
}

export function truncateString(str: string, n: number): string | undefined {
  const wordArray = str.split(" ");
  if (wordArray.length > n) {
    return wordArray.slice(0, n).join(" ") + "...";
  } else {
    return str;
  }
}
