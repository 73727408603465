/* eslint-disable import/export */
import loadable from "@loadable/component";

export * from "./Profile";
export * from "./Footer";
export * from "./Story";
export * from "./Statement";
export * from "./SimpleTextBlock";
export * from "./Form";
export * from "./Quote";
export * from "./People";
export * from "./Image";
export * from "./Article";
export * from "./Statistics";
export * from "./Blocks";
export * from "./CenterCarousel";
export * from "./CenterCarouselContent";
export * from "./Expander";
export * from "./Stories";
export * from "./HillsAndValleysCarousel";
export * from "./HillsAndValleysCarouselContent";
export * from "./ProfilesCarousel";
export * from "./WideCarousel";
export * from "./WideCarouselContent";
export * from "./Sector";
export * from "./StatementCarousel";
export * from "./Infographic";
export * from "./PageLink";
export * from "./Collection";
export * from "./CollectionContent";
export * from "./Menu";
export * from "./HillsAndValleysCollection";
export * from "./SectorLeadership";
export * from "./PeopleCollection";
export * from "./InfographicIntroduction";
export * from "./ValuesCollection";
export * from "./CustomSearch";
export * from "./Links";
export * from "./GA_slider";

export const Dots = loadable(() => import("./Dots"));
export const Hero = loadable(() => import("./Hero"));
export const PositionedAsset = loadable(() => import("./PositionedAsset"));
export const Lever = loadable(() => import("./Lever"));
