import classNames from "classnames";
import React from "react";

export const SearchIcon: React.FC<{ className?: string }> = ({ className }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 17.539 17.475"
    className={classNames("icon inline-block fill-current", className)}
  >
    <g id="Group_1187" data-name="Group 1187" transform="translate(-1117 -30)">
      <path
        d="M1.526,71.04l4.659-4.632L4.659,64.88,0,69.512Z"
        transform="translate(1117 -23.565)"
        fill="#ffffff"
      />
      <path
        d="M16,7.705a7.367,7.367,0,1,1,7.367,7.4A7.367,7.367,0,0,1,16,7.705Zm2.161,0a5.208,5.208,0,1,0,5.206-5.243,5.208,5.208,0,0,0-5.206,5.243Z"
        transform="translate(1103.806 29.63)"
        fill="#ffffff"
      />
    </g>
  </svg>
);
