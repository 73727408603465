/* eslint-disable no-self-assign */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable react-hooks/rules-of-hooks */
import classNames from "classnames";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { useCallback, useState } from "react";

import linkedinIcon from "../images/pip-linkedin.svg";
import { I18Link } from "./Links";

export interface ProfileInformation {
  link?: string;
  sector?: string;
  country?: string;
  image?: IGatsbyImageData;
  jobTitle?: string;
  company?: string;
  linkedin?: string;
  name: string;
  quote?: string;
}

interface Props extends ProfileInformation {
  showSector?: boolean;
  showTitle?: boolean;
  showQuote?: boolean;
  showCountry?: boolean;
  hoverExpand?: boolean;
}

export const SingleProfile: React.FC<Props> = ({
  showSector,
  showTitle,
  showQuote,
  hoverExpand,
  showCountry,
  sector,
  country,
  image,
  jobTitle,
  linkedin,
  name,
  quote,
  link,
}) => {
  if (typeof window === "undefined" || !window.document) {
    return;
  }
  const path = window.location.pathname;
  const [expand, setExpand] = useState(false);
  const showFull = hoverExpand && expand;
  const doExpand = useCallback(() => {
    if (hoverExpand) setExpand(true);
  }, [hoverExpand]);
  const doCollapse = useCallback(() => {
    if (hoverExpand) setExpand(false);
  }, [hoverExpand]);

  const inside = (
    <>
      {image && (
        <div
          className={classNames(
            "aspect-w-1 aspect-h-1",
            showFull ? "opacity-30" : "opacity-100"
          )}
        >
          <GatsbyImage
            style={{ position: "absolute" }}
            className="rounded-full"
            imgStyle={{
              objectFit: "cover",
              objectPosition: "top",
            }}
            image={image}
            alt={name}
          />
        </div>
      )}
      <div
        className={classNames(
          "md:transform-gpu",
          showFull ? "md:-translate-y-23/12" : "md:translate-y-none"
        )}
      >
        <h2 className={classNames("h3 mt-10px")}>{name}</h2>
        {showTitle && jobTitle && (
          <p className={classNames("p4 mt-10px")}>{jobTitle}</p>
        )}
        {showSector && sector && (
          <p className={classNames("p4 mt-10px")}>{sector}</p>
        )}
        {showCountry && country && (
          <p className={classNames("p4 mt-10px")}>{country}</p>
        )}

        <div className={classNames("flex mt-20px")}>
          {console.log("linkedin")}
          {console.log(linkedin)}
          {!hoverExpand && linkedin && (
            <a href={linkedin}>
              <img src={linkedinIcon} alt="linkedin" />
            </a>
          )}
        </div>
        <div
          className={classNames(
            "hidden md:block md:absolute md:w-full md:top-full overflow-hidden",
            showFull ? "md:max-h-massive" : "md:max-h-none"
          )}
        >
          {showQuote && quote && (
            <p className={classNames("p4 mt-20px")}>‟{quote}”</p>
          )}
          {link && (
            <p className="read-more block pt-20px">
              {path == "/en/" ? "View profile" : "Ver perfil"}
            </p>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div
      data-component="Single Profile"
      className="relative"
      onMouseOver={doExpand}
      onFocus={doExpand}
      onMouseOut={doCollapse}
      onBlur={doCollapse}
      role="button"
      tabIndex={0}
    >
      {/* {path == "en" ? (link = link) : (link = "../pt_br" + link)} */}
      {link ? <I18Link to={link}>{inside}</I18Link> : inside}
    </div>
  );
};
